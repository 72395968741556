import FooterMobileBlockProps from "../props/FooterMobileBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";

const FooterMobileBlockStyles = styled.div<FooterMobileBlockProps>`
  position: relative;
  z-index: 101;
  margin: 105px 0 92px 0;
  border-top: 1px solid #E5E5E5;
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} {
      
    }


  }
  
  
  

`

export default FooterMobileBlockStyles
