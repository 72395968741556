import StrengthsMobileBlockProps from "../props/StrengthsMobileBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";

const StrengthsMobileBlockStyles = styled.div<StrengthsMobileBlockProps>`

  .smallBottomBack {
    display: none;
  }
  
  .smallTopBack {
    display: none;
  }

  @media screen and (max-width: 390px) {
    .bigBottomBack {
      display: none;
    }

    .bigTopBack {
      display: none;
    }
    
    .smallBottomBack {
      display: block;
    }

    .smallTopBack {
      display: block;
    }
  }
  
`

export default StrengthsMobileBlockStyles
