import OpenRestoranBlockProps from "../props/OpenRestoranBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";

const OpenRestoranBlockStyles = styled.div<OpenRestoranBlockProps>`

  @media screen and (max-width: 1700px) {
    & {
      height: 100%;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer} > ${StyledText} {
      font-size: 64px;
      width: 597px;
      height: 152px;
      margin-left: 24px;
      padding: 20px 0 0 0;
      line-height: 76px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer} > ${StyledText} {
      font-size: 32px;
      width: 674px;
      height: 35px;
      margin-left: 24px;
      padding: 0 0 0 0;
      line-height: 76px;
    }
  }
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} > ${StyledFlexContainer} > ${StyledFlexContainer}:nth-child(3) {
      margin: -62px 0 0 0;
      flex-direction: column-reverse;
    }
  }
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} > ${StyledFlexContainer} > ${StyledFlexContainer} > ${StyledButton} {
      margin: 24px 0 0 24px;
       }
    }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}{
      margin: 150px 0 0 60px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledImage} {
      top: 90px;
      width: 540px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${BlockStyles} {
      margin: 35px 0 0 0;
      height: 156px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${BlockStyles}> ${StyledFlexContainer} {
      padding: 24px 0 0 60px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${BlockStyles} > ${StyledFlexContainer} > ${StyledImage} {
      width: 214px;      
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${BlockStyles} > ${StyledFlexContainer} > ${StyledFlexContainer} > ${StyledText} {
      font-size: 24px;
      margin: 0;
    }
  }
`

export default OpenRestoranBlockStyles
