import OurTeamBlockProps from "../props/OurTeamBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";

const OurTeamBlockStyles = styled.div<OurTeamBlockProps>`
  position: relative;
  z-index: 10;
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} {
      
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledImage} {
      width: 598px;
      margin: 85px 0 0 60px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledText} {
      font-size: 64px;
      line-height: 75.52px;
      margin: 76px 0 0 0;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles} {
      grid-row-gap: 77px;
      grid-column-gap: 19px;
    }
  } 

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledImage} {
      width: 119px;
    }
  } 
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer} {
      column-gap: 10px;
    }
  } 
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledText}:nth-child(1) {
      font-size: 20px;
      line-height: 23.6px;
      max-width: 150px;
    }
  } 
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledText}:nth-child(2) {
      font-size: 16px;
      line-height: 18.88px;
    }
  } 
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer} {
      
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledImage}:nth-child(1) {
      width: 196px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledImage}:nth-child(2) {
      width: 113px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${ButtonStyles} {
      width: 290px;
    }
  }


`

export default OurTeamBlockStyles
