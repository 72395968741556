import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import LookAtUsBlockStyles from "../styles/LookAtUsBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

import Pic1 from "../media/1.png"
import Pic2 from "../media/2.png"
import Pic3 from "../media/3.png"
import Pic4 from "../media/4.png"
import Pic5 from "../media/5.png"
import Pic6 from "../media/6.png"
import Pic7 from "../media/7.png"
import Pic8 from "../media/8.png"
import Pic9 from "../media/9.png"



const LookAtUsBlockPage = () => {

    return (
        <LookAtUsBlockStyles>
            <BlockPage width="100%" height="100%" >
                <FlexContainerPage flexDirection="column" $alignItems="center" padding="150px 0 72px 0" position="relative" zIndex={10}>
                    <TextPage fontSize="100px" lineHeight="118px" fontWeight="800" color="red">
                        ПОСМОТРИ НА НАС
                    </TextPage>
                    <FlexContainerPage columnGap="40px" margin="72px 0 0 0">
                        <FlexContainerPage flexDirection="column" $justifyContent="flex-start" rowGap="40px">
                            <ImagePage src={Pic1} />
                            <ImagePage src={Pic2} />
                            <ImagePage src={Pic3} />
                            <ImagePage src={Pic4} position="absolute" margin="calc(100% + 40px) 0 0 0" className="specialBoy"/>
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $justifyContent="flex-start" rowGap="40px">
                            <ImagePage src={Pic5} />
                            <ImagePage src={Pic6} />
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $justifyContent="flex-start" rowGap="40px">
                            <FlexContainerPage flexDirection="column" margin="80px 0 0 0">
                                <TextPage fontSize="36px" lineHeight="42.48px" color="red" fontWeight="800">
                                    Атмосфера
                                </TextPage>
                                <TextPage fontSize="24px" lineHeight="28.32px" color="black" fontWeight="700" width="472px" margin="16px 0 0 0">
                                    в наших ресторанах начинается от дверной ручки и заканчивается музыкальным и световым оформлением.
                                </TextPage>
                                <br/>
                                <TextPage fontSize="24px" lineHeight="28.32px" color="black" fontWeight="700" width="472px">
                                    главное правильно, которого мы придерживаемся при создании дизайн-проектов - для гостя ресторан должен быть местом отдыха!
                                </TextPage>
                                <br/>
                                <TextPage fontSize="24px" lineHeight="28.32px" color="black" fontWeight="700" width="472px">
                                    поэтому мы используем приглушенные спокойные цвета, много зелени и натуральные материалы в оформлении.
                                </TextPage>
                            </FlexContainerPage>
                            <ImagePage src={Pic7} className="extraImg"/>
                            <ImagePage src={Pic8} />
                            <ImagePage src={Pic9} />
                        </FlexContainerPage>
                    </FlexContainerPage>
                </FlexContainerPage>
            </BlockPage>
        </LookAtUsBlockStyles>
    );
};

export default LookAtUsBlockPage;