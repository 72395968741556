import OpenTheWorldOfAsiaMobileBlockProps from "../props/OpenTheWorldOfAsiaMobileBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";

const OpenTheWorldOfAsiaMobileBlockStyles = styled.div<OpenTheWorldOfAsiaMobileBlockProps>`
  
  .smallBack {
    display: none;
  }
  .smallFace {
    display: none;
  }
  
  @media screen and (max-width: 390px) {
    .smallBack {
      display: flex;
    }
    .bigBack {
      display: none;
    }
    .smallFace {
      display: flex;
    }
    .bigFace {
      display: none;
    }
  }
  @media screen and (min-width: 391px) {
    .inputBottom {
      margin: 400px 16px 0  0;
    }
    .bottomBlock {
      align-items: center;
    }
    .bottomBlock> ${StyledText} {
      width: 100%;
      text-align: center;
    }
    .bigFace {
      margin-top: 260px;
    }
  }

  @media screen and (min-width: 490px) {
    .cities {
      margin-left: 10%;
    }
    
    .openWorldOfAsia {
      margin-left: 10%;
    }
  }
  .inputBottom {
    margin-left: 10%;
  }
    
`

export default OpenTheWorldOfAsiaMobileBlockStyles
