import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import HowDoWeWorkWithClientBlockStyles from "../styles/HowDoWeWorkWithClientBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

import FirstImage from "../media/1.png"
import SecondImage from "../media/2.png"
import ThirdImage from "../media/3.png"
import FourthImage from "../media/4.png"
import FifthImage from "../media/5.png"
import SixthImage from "../media/6.png"
import SeventhImage from "../media/7.png"
import EightthImage from "../media/8.png"
import NinethImage from "../media/9.png"

import BackgroundImage from "../media/Шарик.png"

import {CardsData} from "../components/CardsData";
import Button from "../../../../components/Button/page/Button";


const HowDoWeWorkWithClientBlockPage = () => {

    return (
        <HowDoWeWorkWithClientBlockStyles>
        <BlockPage width="100%" $backgroundColor="#B2F0A0" height="1665px" id="open">
                <ImagePage src={BackgroundImage} position="absolute" zIndex={1} right="0" margin="334px 0 0 0" width="42%"/>
            <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center" position="relative" zIndex={2}>
                <TextPage color="red" fontSize="100px" fontWeight="800" textAlign="center" lineHeight="118px" margin="62px 0 0 0" width="1084px">
                    КАК МЫ РАБОТАЕМ С ПАРТНЕРОМ
                </TextPage>
                <Grid margin="96px 0 0 0" gridTemplateColumns="repeat(3, 1fr)" gridRowGap="80px" gridColumnGap="40px">
                    {
                        CardsData.map((card) => {
                            return (
                                <FlexContainerPage flexDirection="column"  width="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                                    <ImagePage src={card.image} width="146px" margin="-106px 0 0 0"/>
                                    <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                                        {card.title}
                                    </TextPage>
                                    <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                                        {card.description}
                                    </TextPage>
                                </FlexContainerPage>
                            )
                        })
                    }
                </Grid>
                <Button border="2px solid red" borderRadius="12px" $backgroundColor="white" width="370px" margin="24px 0 0 0">
                    <TextPage fontWeight="600" fontSize="24px" textAlign="center" color="red" padding="0 40px 0 40px">
                        стать партнером
                    </TextPage>
                </Button>
            </FlexContainerPage>
        </BlockPage>
        </HowDoWeWorkWithClientBlockStyles>
    );
};

export default HowDoWeWorkWithClientBlockPage;