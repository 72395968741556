import StyledButton from "../styles/ButtonStyles";
import ButtonProps from "../props/ButtonProps";

const Button = ({children, ...props}:ButtonProps) => {
    return (
        <StyledButton {...props}>
            {children}
        </StyledButton>
    );
};

export default Button;