import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import BlubsWithTextMobileBlockStyles from "../styles/BlubsWithTextMobileBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import Button from "../../../../components/Button/page/Button";

import BackLayer from "../media/ПРОЗРАЧНЫЕ УСЛОВИЯ.png"
import MaskLayer from "../media/Mask Group.png"
import DropLayer from "../media/Vector 1.png"

import BackLayer1 from "../media/ПРО– ЗРАЧНЫЕ УСЛОВИЯ.png"
import MaskLayer1 from "../media/Mask Group2.png"
import DropLayer1 from "../media/Vector 2.png"

import Background from "../media/Ellipse 103.png";
import BlueBack from "../media/ВТОРОЕ ОТКРЫТИЕ ПОЛНОЦЕННОГО ПОЛУКРУГА.png";
import SmallBack from "../media/Я ОТКРЫЛ ПОЛНОЦЕННЫЙ ПОЛУКРУГ.png"


const BlubsWithTextMobileBlockPage = () => {

  return (
    <BlubsWithTextMobileBlockStyles>
      <BlockPage width="100%">
        <FlexContainerPage position="relative">
          <ImagePage src={Background} position="absolute" zIndex={1} className="bigRedBack"/>
          <ImagePage src={SmallBack} position="absolute" zIndex={1} className="smallBack"/>
          <ImagePage src={BlueBack} position="absolute" zIndex={1} margin="450px 0 0 0" className="bigBlueBack"/>
        </FlexContainerPage>
        <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center" position="relative"
                           zIndex={5} padding="155px 0 200px 0" margin="">
          <FlexContainerPage $alignItems="center" $justifyContent="center" margin="100px 40px 150px 40px" className="maskGroup1">
            <ImagePage src={BackLayer} position="absolute" zIndex={2}/>
            <ImagePage src={MaskLayer} position="absolute" zIndex={3} margin="-30px 0 0 -20px"/>
            <ImagePage src={DropLayer} position="absolute" zIndex={4} margin="-30px 0 0 -20px"/>
          </FlexContainerPage>
          <FlexContainerPage $alignItems="center" $justifyContent="center" margin="220px 40px 150px -20px" className="maskGroup2">
            <ImagePage src={BackLayer1} position="absolute" zIndex={2} width="280px"/>
            <ImagePage src={MaskLayer1} position="absolute" zIndex={3} margin="-20px 0 0 130px" width="290px"/>
            <ImagePage src={DropLayer1} position="absolute" zIndex={4} margin="20px 0 0 104px" width="340px"/>
          </FlexContainerPage>
          <FlexContainerPage flexDirection="column" position="relative" zIndex={4} margin="57px 0 0 0"
                rowGap="48px" className="mainText" $alignItems="center">
            <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center" margin="0 20px 0 20px">
              <TextPage fontSize="48px" lineHeight="75.52px" fontWeight="800" color="white">
                окупаемость
              </TextPage>
              <TextPage fontSize="24px" lineHeight="28.8px" fontWeight="800" color="white" maxWidth="420px"
                        textAlign="center" >
                средний срок окупаемости ресторана - 15 месяцев
              </TextPage>
              <ImagePage src="" position="absolute" zIndex={2} margin="-200px 0 0 385px" />
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center">
              <TextPage fontSize="48px" lineHeight="75.52px" fontWeight="800" color="white">
                первый взнос
              </TextPage>
              <TextPage fontSize="24px" lineHeight="28.8px" fontWeight="800" color="white" maxWidth="420px"
                        textAlign="center" margin="0 20px 0 20px">
                первоначальный взнос
                от 500 000 рублей
              </TextPage>
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center">
              <TextPage fontSize="48px" lineHeight="75.52px" fontWeight="800" color="white">
                прибыль
              </TextPage>
              <TextPage fontSize="24px" lineHeight="28.8px" fontWeight="800" color="white" maxWidth="420px"
                        textAlign="center" margin="0 20px 0 20px">
                финансовый результат
                от 18% до 20%
              </TextPage>
            </FlexContainerPage>

            <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center">
              <TextPage fontSize="48px" lineHeight="75.52px" fontWeight="800" color="white">
                инвестиции
              </TextPage>
              <TextPage fontSize="24px" lineHeight="28.8px" fontWeight="800" color="white" maxWidth="420px"
                        textAlign="center" margin="0 20px 0 20px">
                общие инвестиции
                от 4 000 000 рублей
              </TextPage>
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center">
              <TextPage fontSize="48px" lineHeight="75.52px" fontWeight="800" color="white">
                роялти
              </TextPage>
              <ImagePage src="" position="absolute" zIndex={2} margin="-35px 0 0 -260px" />
              <TextPage fontSize="24px" lineHeight="28.8px" fontWeight="800" color="white" maxWidth="350px"
                        textAlign="center" margin="0 20px 0 20px">
                низкая процентная ставка -
                до 5%
              </TextPage>
            </FlexContainerPage>
          </FlexContainerPage>

          <ImagePage src="" margin="-25px 0 0 950px" />
        </FlexContainerPage>
      </BlockPage>
    </BlubsWithTextMobileBlockStyles>
  );
};

export default BlubsWithTextMobileBlockPage;