import styled from "styled-components";
import InputProps from "../props/InputProps";

export const StyledInput = styled.input<InputProps>`
    width: ${({width}) => width ? width : ''};
    height: ${({height}) => height ? height : ''};
    margin: ${({margin}) => margin ? margin : ''};
    padding: ${({padding}) => padding ? padding : ''};
    border: ${({border}) => border ? border : ''};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : ''};    
    background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : ''};    
    font-size: ${({fontSize}) => fontSize ? fontSize : ''};    
    font-weight: ${({fontWeight}) => fontWeight ? fontWeight : ''}; 
    min-width: ${({minWidth}) => minWidth ? minWidth : ''};
  
    &::placeholder {
      color: ${({color}) => color ? color : ''};
    }
`