import OurProductMobileBlockProps from "../props/OurProductMobileBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";

const OurProductMobileBlockStyles = styled.div<OurProductMobileBlockProps>`
  
  .smallBack {
    display: none;
  }
  .smallTartar {
    display: none;
  }
  .bigBack {
    left: -100px;
    overflow: hidden;
    width: 900px;
  }
  .bigTartar {
    width: 800px;
    left: -150px;
  }

  @media screen and (max-width: 560px) {    
    .smallTartar {      
      display: block;
      width: 420px;
      left: 0;
      margin-top: -80px;
    }
    .bigTartar {
      display: none;
    }
    .bottomText {
      margin-top: 180px;
    }
    .bottomText> ${StyledText} {
      font-size: 24px;
      line-height: 28.8px;
      font-weight: 500;
    }
    
    .topText> ${StyledText} {
      font-size: 64px;
      line-height: 75.52px;
    }
    
  }

  @media screen and (max-width: 390px) {
    .smallBack {
      display: block;
      margin-top: -80px;
      left: 0;
    }
    .bigBack {
      display: none;
    }

    .smallTartar {
      display: block;
      width: 390px;
      left: 0;
    }
    
    
  }
  
  @media screen and (max-width: 520px) {
    
  }
`

export default OurProductMobileBlockStyles
