import HowDoWeWorkWithClientBlockProps from "../props/HowDoWeWorkWithClientBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";

const HowDoWeWorkWithClientBlockStyles = styled.div<HowDoWeWorkWithClientBlockProps>`
  position: relative;
  z-index: 6;
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} {
      width: 100%;
      height: 1230px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledImage} {
      margin: 180px 0 0 0;
      width: 650px;
      right: 0px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledText} {
      font-size: 60px;
      line-height: 75.52px;
      width: 900px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles} {
      margin: 64px 0 0 0;
      grid-row-gap: 40px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${ButtonStyles} {
      width: 294px;
      padding: 12px 20px 12px 20px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${ButtonStyles}> ${StyledText} {
      
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer} {
      width: 291px;
      height: 228px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledImage} {
      width: 101px;
      margin: -80px 0 0 0;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(2) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(3) {
      font-size: 16px;
      line-height: 19.2px;
    }
  }
`

export default HowDoWeWorkWithClientBlockStyles
