import {StyledText} from "../styles/TextStyles";
import TextProps from "../props/TextProps";

const TextPage = ({children, ...props}:TextProps) => {
    return (
        <StyledText {...props}>
            {children}
        </StyledText>
    );
};

export default TextPage