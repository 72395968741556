import React from 'react';
import SushiVeslaTodayStyles from "../styles/SushiVeslaTodayStyles";
import BlockPage from "../../../../components/Block/page/BlockPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import TextPage from "../../../../components/Text/page/TextPage";
import Button from "../../../../components/Button/page/Button";
import Grid from "../../../../components/container/Grid/page/Grid";

const SushiVeslaTodayPage = () => {
    return (
        <SushiVeslaTodayStyles>
            <BlockPage height="1080px" id="about">
                <FlexContainerPage flexDirection="column" $justifyContent="center" $alignItems="center" margin="255px 0 0 0">
                    <TextPage fontWeight="800" fontSize="100px" color="white" backgroundColor="#8ED8F7"
                              padding="32px 78px 32px 78px" borderRadius="50px" position="relative" zIndex="4">
                        СЕГОДНЯ СУШИВЕСЛА - ЭТО:
                    </TextPage>
                    <FlexContainerPage width="1454px" height="790px" backgroundColor="#FF0032" margin="-182px 0 0 0" borderRadius="150px" flexDirection="column">
                        <Grid margin="233px 32px 0 0" width="100%" gridTemplateColumns="repeat(3, 1fr)">
                            <FlexContainerPage flexDirection="column" $alignItems="center">
                                <TextPage color="white" fontWeight="800" fontSize="72px" lineHeight="84.96px">
                                    123
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="36px" lineHeight="43.2px" textAlign="center">
                                    ресторана <br/>
                                    в 24 регионах России
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage flexDirection="column" $alignItems="center">
                                <TextPage color="white" fontWeight="800" fontSize="72px" lineHeight="84.96px">
                                    8 млн
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="36px" lineHeight="43.2px" textAlign="center">
                                    гостей по сети<br/>
                                    ежегодно
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage flexDirection="column" $alignItems="center">
                                <TextPage color="white" fontWeight="800" fontSize="72px" lineHeight="84.96px">
                                    5 000
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="36px" lineHeight="43.2px" textAlign="center">
                                    заказов<br/>
                                    каждый день
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage flexDirection="column" $alignItems="center" flexGrow="1" margin="44px 0 0 0">
                                <TextPage color="white" fontWeight="800" fontSize="72px" lineHeight="84.96px">
                                    1,5 года
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="36px" lineHeight="43.2px" textAlign="center">
                                    средняя окупаемость ресторана
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage flexDirection="column" $alignItems="center" margin="44px 0 0 0">
                                <TextPage color="white" fontWeight="800" fontSize="72px" lineHeight="84.96px">
                                    6 млн
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="36px" lineHeight="43.2px" textAlign="center">
                                    наибольшая выручка<br/>
                                    за месяц
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage flexDirection="column" $alignItems="center" margin="44px 0 0 0">
                                <TextPage color="white" fontWeight="800" fontSize="72px" lineHeight="84.96px">
                                    60%
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="36px" lineHeight="43.2px" textAlign="center">
                                    гостей совершают повторный заказ
                                </TextPage>
                            </FlexContainerPage>
                        </Grid>
                        <FlexContainerPage position="relative" zIndex={4} $justifyContent="center" width="100%" margin="40px 0 0 0">
                            <Button width="370px" height="56px" $backgroundColor="white" color="red" fontSize="24px" fontWeight="600" borderRadius="12px" cursor="pointer">
                                получить звонок
                            </Button>
                        </FlexContainerPage>
                    </FlexContainerPage>

                </FlexContainerPage>
            </BlockPage>
        </SushiVeslaTodayStyles>
    );
};

export default SushiVeslaTodayPage;