import {BlockStyles} from "../styles/BlockStyles";
import BlockProps from "../props/BlockProps";

const BlockPage = ({children, ...props}:BlockProps) => {
    return (
        <BlockStyles {...props}>
            {children}
        </BlockStyles>
    );
};

export default BlockPage;