import BlubsWithTextMobileBlockProps from "../props/BlubsWithTextMobileBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";

const BlubsWithTextMobileBlockStyles = styled.div<BlubsWithTextMobileBlockProps>`
  
  .smallBack {
    display: none;
  }
  .maskGroup2 {
    display: none;
  }
  
  @media screen and (max-width: 630px) {
   .maskGroup1> ${StyledImage}:nth-child(1){
     width: 380px;
   }
    .maskGroup1> ${StyledImage}:nth-child(2){
      width: 200px;
    }
    .maskGroup1> ${StyledImage}:nth-child(3){
      width: 200px;
    }
  }

  @media screen and (max-width: 390px) {
    .smallBack {
      display: block;
    }
    .bigRedBack {
      display: none;
    } 
    .maskGroup1 {
      display: none;
    }
    .maskGroup2 {
      display: flex;
    }
    .mainText {
      align-items: flex-start;
      margin: 0 20px 0 20px;
    }
    .mainText> ${StyledFlexContainer} {
      align-items: flex-start;
      justify-content: flex-start;
     
    }
    .mainText> ${StyledFlexContainer}> ${StyledText} {
      text-align: left;
      margin: 0;
    }
  }

`

export default BlubsWithTextMobileBlockStyles
