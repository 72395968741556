import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import RestaurantFormatsMobileBlockStyles from "../styles/RestaurantFormatsMobileBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

import Logo from "../../../../img/Frame1.svg"

import FirstFormat from "../../../../img/Rectangle 349.png"
import SecondFormat from "../../../../img/Rectangle 350.png"
import ThirdFormat from "../../../../img/Rectangle 351.png"
import FourthFormat from "../../../../img/Rectangle 352.png"
import Button from "../../../../components/Button/page/Button";


const RestaurantFormatsMobileBlockPage = (props:any) => {

    return (
        <RestaurantFormatsMobileBlockStyles>
            <BlockPage width="100%" id="formats">
                <FlexContainerPage flexDirection="column" $alignItems="center" margin="300px 0 100px 24px" position="relative" zIndex={3}>
                    <FlexContainerPage flexDirection="column" >
                        <ImagePage src={Logo} width="337px"/>
                        <TextPage fontSize="64px" fontWeight="800" lineHeight="75.52px" color="red" margin="24px 0 24px 0" className="wrapText">
                            ФОРМАТЫ РЕСТО-
                            <br/>
                            РАНОВ
                        </TextPage>
                        <TextPage fontSize="64px" fontWeight="800" lineHeight="75.52px" color="red" margin="24px 0 24px 0" className="noWrapText">
                            ФОРМАТЫ РЕСТОРАНОВ
                        </TextPage>
                    </FlexContainerPage>
                    <Grid  gridTemplateColumns="repeat(2, 0fr)" gridRowGap="16px" gridColumnGap="16px" margin="0 0 0 -20px">
                        <FlexContainerPage $alignItems="center" $justifyContent="center" onClick={props.showDarkKitchen}>
                            <ImagePage src={FirstFormat} width="172px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" onClick={props.showFoodCort}>
                            <ImagePage src={SecondFormat} width="172px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" onClick={props.showStreet}>
                            <ImagePage src={ThirdFormat} width="172px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" onClick={props.showBoutique}>
                            <ImagePage src={FourthFormat} width="172px"/>
                        </FlexContainerPage>
                    </Grid>

                    <TextPage fontSize="24px" fontWeight="600" lineHeight="28.8px" color="black" margin="24px 16px 0 0">
                        узнай как открыть
                        франшизу в своём городе Узнай как открыть франшизу в своём городе Узнай как открыть франшизу в своём городе
                    </TextPage>
                    <Button width="266px" color="red" $backgroundColor="white" fontWeight="600" fontSize="18px" border="2px solid red" borderRadius="12px" margin="32px 0 0 0">
                        скачай презентацию
                    </Button>
                </FlexContainerPage>
            </BlockPage>
        </RestaurantFormatsMobileBlockStyles>
    );
};

export default RestaurantFormatsMobileBlockPage;