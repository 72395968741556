import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import OpenTheWorldOfAsiaBlockStyles from "../styles/OpenTheWorldOfAsiaBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

import Fish from "../media/Рыбка 1.png"

import Back1 from "../media/Ellipse 80.png"
import Back2 from "../media/Ellipse 2.png"
import Back3 from "../media/Ellipse 1.png"
import Button from "../../../../components/Button/page/Button";

import CircleOpacity from "../media/Кружочек прозрачный.png"
import CircleCommon from "../media/Кружочек белый.png"
import Input from "../../../../components/Input/page/InputPage";

import Face from "../media/Group 294.png";
import Logo from "../media/Frame.png";




const OpenTheWorldOfAsiaBlockPage = () => {

    return (
        <OpenTheWorldOfAsiaBlockStyles>
            <BlockPage width="100%" $backgroundColor="white" height="100%">
                <ImagePage src={Fish} position="absolute" right="0" margin="60px 0 0 0" zIndex={4}/>
                <ImagePage src={Back1} position="absolute" zIndex={3} margin="-50px 0 0 0"/>
                <ImagePage src={Back2} position="absolute" zIndex={2}/>
                <ImagePage src={Back3} position="absolute" zIndex={1} margin="-50px 0 0 0"/>

                <FlexContainerPage position="relative" zIndex={10}>
                    <FlexContainerPage flexDirection="column" margin="330px 0 0 213px" className="openWorldOfAsia">
                        <TextPage fontSize="100px" fontWeight="800" lineHeight="118px" width="516px" color="darkRed">
                            ОТКРОЙ
                            МИР АЗИИ
                            В СВОЁМ
                            ГОРОДЕ
                        </TextPage>
                        <Button width="313px" border="2px solid red" borderRadius="12px" $backgroundColor="white"
                                color="red" fontSize="24px" fontWeight="600" margin="52px 0 0 0">
                            стать партнером
                        </Button>
                    </FlexContainerPage>

                    <FlexContainerPage flexDirection="column" margin="258px 0 0 154px" className="cities">
                        <FlexContainerPage $alignItems="center" $justifyContent="center">
                            <TextPage fontSize="36px" lineHeight="29.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Екатеринбург
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="164px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="95px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" margin="116px 0 0 220px">
                            <TextPage fontSize="36px" lineHeight="29.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Ставрополь
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="164px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="95px"/>
                        </FlexContainerPage>

                        <FlexContainerPage $alignItems="center" $justifyContent="center" margin="186px 0 0 110px">
                            <TextPage fontSize="36px" lineHeight="29.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Москва
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="305px" className="pulseAsia"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="175px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" margin="-36px 0 0 420px">
                            <TextPage fontSize="36px" lineHeight="29.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Новосибирск
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="164px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="95px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" margin="136px 0 0 380px">
                            <TextPage fontSize="36px" lineHeight="29.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Уфа
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="67px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="39px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" margin="10px 0 0 670px">
                            <TextPage fontSize="36px" lineHeight="39.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} width="195px" textAlign="center">
                                Тюмень
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="173px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="100px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" margin="56px 0 0 0">
                            <TextPage fontSize="36px" lineHeight="39.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} width="195px" textAlign="center">
                                Санкт-Петербург
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="177px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="102px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" margin="-50px 0 0 270px">
                            <TextPage fontSize="36px" lineHeight="39.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} width="195px" textAlign="center">
                                Пермь
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="173px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="100px"/>
                        </FlexContainerPage>
                    </FlexContainerPage>
                </FlexContainerPage>
                <FlexContainerPage position="relative" zIndex={10} margin="253px 0 0 0" $alignItems="center" $justifyContent="center" columnGap="31px" className="inputBottom">
                    <TextPage fontSize="36px" fontWeight="600" lineHeight="43.2px" width="446px" color="darkRed">
                        узнай, как открыть
                        франшизу в своём городе
                    </TextPage>
                    <Input placeholder="email" width="342px" height="56px" border="2px solid red"
                           borderRadius="12px" padding="0 0 0 24px" fontSize="18px" fontWeight="600" color="rgba(0, 0, 0, 0.3)"/>
                    <Button color="red" $backgroundColor="white" width="204px" height="56px" borderRadius="12px" fontSize="24px" fontWeight="600">
                        отправить
                    </Button>
                </FlexContainerPage>
                <FlexContainerPage $alignItems="center" $justifyContent="center"
                                   position="relative" zIndex={10} backgroundColor="#FF0032" height="400px" columnGap="60px" margin="100px 0 0 0" className="worldOfAsiaBottom">
                    <ImagePage src={Logo} width="419px"/>
                    <ImagePage src={Face} width="273px"/>
                    <FlexContainerPage flexDirection="column" margin="0">
                        <TextPage fontSize="48px" lineHeight="56.64px" width="540.87px" color="white" fontWeight="800">
                            основатель кампании
                        </TextPage>
                        <TextPage fontSize="96px" lineHeight="113.28px" width="730.48px" fontWeight="800" color="white">
                            ЕВГЕНИЙ КУПКО
                        </TextPage>
                    </FlexContainerPage>
                </FlexContainerPage>
            </BlockPage>
        </OpenTheWorldOfAsiaBlockStyles>
    );
};

export default OpenTheWorldOfAsiaBlockPage;