import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import LookAtUsMobileBlockStyles from "../styles/LookAtUsMobileBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

import Pic1 from "../media/1.png"
import Pic2 from "../media/2.png"
import Pic3 from "../media/3.png"
import Pic4 from "../media/4.png"
import Pic5 from "../media/5.png"
import Pic6 from "../media/6.png"
import Pic7 from "../media/7.png"
import Pic8 from "../media/8.png"
import Pic9 from "../media/9.png"

import Logo from "../media/Frame.svg"





const LookAtUsMobileBlockPage = () => {

    return (
        <LookAtUsMobileBlockStyles>
            <BlockPage width="100%" height="100%" >
                <FlexContainerPage flexDirection="column" $alignItems="center" padding="58px 0 72px 0" position="relative" zIndex={10}>
                    <FlexContainerPage flexDirection="column" margin="0 16px 0 16px">
                        <ImagePage src={Logo} />
                        <TextPage fontSize="60px" lineHeight="70.8px" fontWeight="800" color="red">
                            ПОСМОТРИ НА НАС
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column" margin="0 16px 0 16px">
                        <TextPage fontSize="24px" lineHeight="42.48px" color="lightBlue" fontWeight="800">
                            Атмосфера
                        </TextPage>
                        <TextPage fontSize="18px" lineHeight="21.24px" color="black" fontWeight="400" maxWidth="472px" margin="16px 0 0 0">
                            в наших ресторанах начинается от дверной ручки и заканчивается музыкальным и световым оформлением.
                        </TextPage>
                        <br/>
                        <TextPage fontSize="18px" lineHeight="21.24px" color="black" fontWeight="400" maxWidth="472px">
                            главное правильно, которого мы придерживаемся при создании дизайн-проектов - для гостя ресторан должен быть местом отдыха!
                        </TextPage>
                        <br/>
                        <TextPage fontSize="18px" lineHeight="21.24px" color="black" fontWeight="400" maxWidth="472px">
                            поэтому мы используем приглушенные спокойные цвета, много зелени и натуральные материалы в оформлении.
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage columnGap="12px" margin="32px 0 0 0">
                        <FlexContainerPage flexDirection="column" $justifyContent="flex-start" rowGap="12px">
                            <ImagePage src={Pic1} width="111px" />
                            <ImagePage src={Pic2} width="111px" />
                            <ImagePage src={Pic3} width="111px" />
                            <ImagePage src={Pic4} width="236px" position="absolute" bottom="70px" className="specialBoy"/>
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $justifyContent="flex-start" rowGap="12px">
                            <ImagePage src={Pic5} width="111px" />
                            <ImagePage src={Pic6} width="111px" />
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $justifyContent="flex-start" rowGap="12px">
                            <ImagePage src={Pic7} width="111px" className="extraImg"/>
                            <ImagePage src={Pic8} width="111px" />
                            <ImagePage src={Pic9} width="111px" />
                        </FlexContainerPage>
                    </FlexContainerPage>
                </FlexContainerPage>
            </BlockPage>
        </LookAtUsMobileBlockStyles>
    );
};

export default LookAtUsMobileBlockPage;