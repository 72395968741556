import FlexContainerProps from "../props/FlexContainerProps";
import styled from "styled-components";

export const StyledFlexContainer = styled.div<FlexContainerProps>`
    display: flex;
    flex-direction: ${({flexDirection}) => flexDirection ? flexDirection : 'row'};
    flex-wrap: ${({flexWrap}) => flexWrap ? flexWrap : 'nowrap'};
    flex-grow: ${({flexGrow}) => flexGrow ? flexGrow : ''};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : ''};
    justify-content: ${({$justifyContent}) => $justifyContent ? $justifyContent : 'flex-start'};
    align-items: ${({$alignItems}) => $alignItems ? $alignItems : 'flex-start'};
    width: ${({width}) => width ? width : ''};
    background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : ''};
    height: ${({height}) => height ? height : ''};
    margin: ${({margin}) => margin ? margin : ''};
    padding: ${({padding}) => padding ? padding : ''};
    cursor: ${({cursor}) => cursor ? cursor : ''};  
    z-index: ${({zIndex}) => zIndex ? zIndex : ''};  
    position: ${({position}) => position ? position : ''};  
    max-width: ${({maxWidth}) => maxWidth ? maxWidth : ''};
    top:  ${({top}) => top ? top : ''};
    right:  ${({right}) => right ? right : ''};
    left:  ${({left}) => left ? left : ''};
    bottom:  ${({bottom}) => bottom ? bottom : ''};
    column-gap: ${({columnGap}) => columnGap ? columnGap : ''};
    row-gap: ${({rowGap}) => rowGap ? rowGap : ''};
    min-height: ${({minHeight}) => minHeight ? minHeight : ''};
`