import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MdClose } from 'react-icons/md';
import { useSpring, animated } from "react-spring";
import { StyledModalCarousel } from "./CarouselModal";
import Grid from "../../../../components/container/Grid/page/Grid";
import ImagePage from "../../../../components/Image/page/ImagePage";

import Img from "../media/Rectangle 391.png"
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import TextPage from "../../../../components/Text/page/TextPage";
import PrevArrow from "../../CarouselBlock/media/Ellipse 111.png";
import NextArrow from "../../CarouselBlock/media/Ellipse 112.png";
import Button from "../../../../components/Button/page/Button";
import { StyledText } from "../../../../components/Text/styles/TextStyles";
import { StyledFlexContainer } from "../../../../components/container/Flex/styles/FlexContainerStyles";

const Thumbs = [Img, Img, Img, Img]

const CustomThumbs = () => {
  const res = Thumbs.map((thumb) =>
    <ImagePage src={thumb} />
  )
  return res
}

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .thumb {
    width: 166px !important;
    border-radius: 24px;
  }
  .thumbs {
    position: absolute;
    left: 0;
    img {
      border-radius: 24px;
    }
  }
  @media screen and (max-width: 1400px){
    .topText> ${StyledText} {
      font-size: 24px;
    }    
    .middleText> ${StyledFlexContainer}> ${StyledText} {
      font-size: 16px;
      line-height: 24px;
    }
    .bottomText> ${StyledText} {
      font-size: 14px;
      line-height: 24px;
    }
    
  }
  @media screen and (max-width: 1200px){
    
  .slide{
    img {
      width: 500px !important;
    }
  }
  .thumb {
    width: 120px !important;
    border-radius: 24px;
  }
  .thumbs {
    position: absolute;
    left: 0px;
    img {
      border-radius: 24px;
    }
  }
  .bottomButton {
    margin: 20px 0 0 0;
  }
  }
`;

const ModalWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  height: 750px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  @media screen and (max-width: 1200px){
    width: 975px;
    height: 600px;
  }
`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  margin-top: 40px;
  margin-right: 70px;
  p {
    margin-bottom: 1rem;
  }
  
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;
const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  width: 50,
  height: 30,
  cursor: 'pointer',
  border: "none",
  backgroundColor: "rgba(0, 0, 0, 0)",
  opacity: 0.5
};

const DarkKitchenModalWindow = ({ showModal, setShowModal }:any) => {



  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
    margin: "0 40px 0 40px"
  });

  const closeModal = (e:any) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
        console.log('I pressed');
      }
    },
    [setShowModal, showModal]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );


  // @ts-ignore
  return (
    <>
      {showModal ? (
        // @ts-ignore
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper
              // @ts-ignore
              showModal={showModal}
            >
              <ModalContent>
                <Grid gridTemplateColumns="repeat(2, 1fr)" gridColumnGap="40px">
                  <StyledModalCarousel
                    infiniteLoop
                    emulateTouch
                    showThumbs={true}
                    showStatus={false}
                    showIndicators={false}
                    // @ts-ignore
                    renderThumbs={CustomThumbs}
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                      hasNext && (
                        <button type="button" onClick={onClickHandler} title={label}
                          //@ts-ignore
                                style={{ ...arrowStyles, right: 0, position:"absolute", zIndex: 100 }}>
                          <ImagePage src={PrevArrow}/>
                        </button>
                      )
                    }
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      hasPrev && (

                        <button type="button" onClick={onClickHandler} title={label}
                          //@ts-ignore
                                style={{ ...arrowStyles, left: 0 }}>
                          <ImagePage src={NextArrow}/>
                        </button>
                      )
                    }
                  >
                    <ImagePage src={Img} width="724px !important" />
                    <ImagePage src={Img} width="724px !important" />
                    <ImagePage src={Img} width="724px !important" />
                    <ImagePage src={Img} width="724px !important" />
                  </StyledModalCarousel>
                  <FlexContainerPage flexDirection="column" width="100%" margin="0 70px 0 0" className="topText">
                    <TextPage color="red" fontSize="36px" lineHeight="43.2px" fontWeight="700">
                      Dark kitchen
                    </TextPage>
                    <FlexContainerPage margin="12px 0 0 0" flexDirection="column">
                    <FlexContainerPage columnGap="25px" className="middleText">
                      <FlexContainerPage flexDirection="column" >
                      <TextPage color="black" fontSize="24px" lineHeight="38.4px" fontWeight="600">
                        Финансовый результат
                      </TextPage>
                        <TextPage color="red" fontSize="24px" lineHeight="38.4px" fontWeight="600">
                          20%
                        </TextPage>
                      </FlexContainerPage>
                      <FlexContainerPage flexDirection="column">
                        <TextPage color="black" fontSize="24px" lineHeight="38.4px" fontWeight="600">
                          Выручка:
                        </TextPage>
                        <TextPage color="red" fontSize="24px" lineHeight="38.4px" fontWeight="600">
                          от 1.5 млн. руб.
                        </TextPage>
                      </FlexContainerPage>
                    </FlexContainerPage>
                    <FlexContainerPage columnGap="25px" margin="12px 0 0 0" className="middleText">
                      <FlexContainerPage flexDirection="column" >
                        <TextPage color="black" fontSize="24px" lineHeight="38.4px" fontWeight="600">
                          Возврат инвестиций:
                        </TextPage>
                        <TextPage color="red" fontSize="24px" lineHeight="38.4px" fontWeight="600">
                          от 18 месяцев
                        </TextPage>
                      </FlexContainerPage>
                      <FlexContainerPage flexDirection="column">
                        <TextPage color="black" fontSize="24px" lineHeight="38.4px" fontWeight="600">
                          Инвестиции
                        </TextPage>
                        <TextPage color="red" fontSize="24px" lineHeight="38.4px" fontWeight="600">
                          2.0-3.0 млн. руб.
                        </TextPage>
                      </FlexContainerPage>
                    </FlexContainerPage>
                    </FlexContainerPage>
                    <FlexContainerPage margin="16px 0 0 0" padding="16px 0 0 0" className="bottomText">
                      <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px">
                        Dark Kitchen - будущее за доставкой!
                        <br/>
                        <br/>
                        Мы предлагаем новый формат ресторанов. Dark kitchen - кухня, которая готовит еду только на доставку или самовывоз.
                        <br/>
                        <br/>
                        Низкие инвестиции и экономия операционных затрат. Высокая выручка от доставки.
                      </TextPage>
                    </FlexContainerPage>
                    <Button borderRadius="12px" border="2px solid red" color="red"
                            fontSize="18px" fontWeight="800" padding="15px 34px 15px 34px" margin="150px 0 0 0" className="bottomButton">
                      оставить заявку
                    </Button>
                  </FlexContainerPage>
                </Grid>

              </ModalContent>
              <CloseModalButton
                aria-label='Close modal'
                // @ts-ignore
                onClick={() => setShowModal(prev => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};

export default DarkKitchenModalWindow;