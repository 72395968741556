import LookAtUsMobileBlockProps from "../props/LookAtUsMobileBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";

const LookAtUsMobileBlockStyles = styled.div<LookAtUsMobileBlockProps>`
  position: relative;
  z-index: 100;
  
  @media screen and (max-width: 1700px) {
   
  }
  
  
  

`

export default LookAtUsMobileBlockStyles
