import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import OurTeamBlockStyles from "../styles/OurTeamBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import {TeamCards} from "../components/TeamCards";

import Back from "../media/Ellipse 74.png"
import ButtonBack1 from "../media/Ellipse 1.png"
import ButtonBack2 from "../media/Ellipse 2.png"
import Button from "../../../../components/Button/page/Button";




const OurTeamBlockPage = () => {

    return (
        <OurTeamBlockStyles>
            <BlockPage width="100%" $backgroundColor="#8ED8F7" height="100%">
                <ImagePage src={Back} margin="16px 0 0 78px" position="absolute" zIndex={6}/>
                <FlexContainerPage flexDirection="column" position="relative" zIndex={7} $justifyContent="center" $alignItems="center">
                    <TextPage color="white" width={"782px"} textAlign="center" fontSize="96px" lineHeight="113px" fontWeight="800" margin="163px 0 0 0">
                        НАША КОМАНДА
                    </TextPage>
                    <Grid gridTemplateColumns="repeat(3, 1fr)" gridColumnGap="140px" gridRowGap="100px" margin="100px 0 0 0">
                        {TeamCards.map((card) => {
                            return(
                                <FlexContainerPage columnGap="16px" $justifyContent="center" $alignItems="center">
                                    <ImagePage src={card.img} />
                                    <FlexContainerPage flexDirection="Column" >
                                        <TextPage fontSize="36px" fontWeight="800" lineHeight="42.48px" color="white" maxWidth="200px">
                                            {card.title}
                                        </TextPage>
                                        <TextPage fontSize="24px" fontWeight="400" lineHeight="28.32px" color="red">
                                            {card.description}
                                        </TextPage>
                                    </FlexContainerPage>
                                </FlexContainerPage>
                            )

                        })}
                    </Grid>
                    <FlexContainerPage $alignItems="center" $justifyContent="center" height="350px">
                        <ImagePage src={ButtonBack1} position="absolute"/>
                        <ImagePage src={ButtonBack2} position="absolute"/>
                        <Button color="red" $backgroundColor="white" width="370px" borderRadius="12px"
                                fontSize="24px" fontWeight="600" position="relative" border="2px solid red">
                            стать партнером
                        </Button>
                    </FlexContainerPage>

                </FlexContainerPage>
            </BlockPage>
        </OurTeamBlockStyles>
    );
};

export default OurTeamBlockPage;