import LookAtUsBlockProps from "../props/LookAtUsBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";

const LookAtUsBlockStyles = styled.div<LookAtUsBlockProps>`
  position: relative;
  z-index: 100;
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} {
      
    }  
    
    & > ${BlockStyles}> ${StyledFlexContainer} {
      padding: 72px 0 72px 0;
    }
    
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledText} {
      font-size: 64px;
      line-height: 75.52px;
    }
      
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer} {
      column-gap: 16px;
      margin: 72px 60px 0 60px;
    }
    
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledFlexContainer} {
      row-gap: 16px;
    }
   
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledImage} {
      width: 290px;
    }
    
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledText} {
      width: 290px;
      font-size: 24px;
      line-height: 25.6px;
      font-weight: 400;
    }
    
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledText}:nth-child(1) {
      width: 290px;
      font-size: 48px;
      line-height: 56.64px;
      font-weight: 800;
    }
    
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledFlexContainer} {
     margin: 0;
    }
   
    .specialBoy {
      width: 597px !important;
      margin: 1184px 0 0 0;
    }
    .extraImg {
      display: none;
    }
  }
  
  
  

`

export default LookAtUsBlockStyles
