import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import HowDoWeWorkWithClientMobileBlockStyles from "../styles/HowDoWeWorkWithClientMobileBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

import FirstImage from "../media/1.png";
import SecondImage from "../media/2.png";
import ThirdImage from "../media/3.png";
import FourthImage from "../media/4.png";
import FifthImage from "../media/5.png";
import SixthImage from "../media/6.png";
import SeventhImage from "../media/7.png";
import EightthImage from "../media/8.png";
import NinethImage from "../media/9.png";

import Button from "../../../../components/Button/page/Button";
import { useState } from "react";


const HowDoWeWorkWithClientMobileBlockPage = () => {

  const [isOpen, setOpen] = useState("closed")

  const toggle = () => {
    isOpen === "closed"? setOpen("open") : setOpen("closed")
  }

  return (
    <HowDoWeWorkWithClientMobileBlockStyles>
      <BlockPage width="100%" $backgroundColor="#B2F0A0" height="100%" id="open">
        <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center" position="relative"
                           zIndex={2} className="topText">
          <TextPage color="red" fontSize="60px" fontWeight="800" textAlign="center" lineHeight="70.8px"
                    margin="62px 0 0 0">
            РАБОТА С
            ПАРТНЕРОМ
          </TextPage>
          <Grid margin="96px 0 70px 0" gridTemplateColumns="repeat(1, 0fr)" gridRowGap="80px" gridColumnGap="40px">
            <FlexContainerPage flexDirection="column" width="358px" height="292px" backgroundColor="white"
                               borderRadius="24px" $justifyContent="center" $alignItems="center">
              <ImagePage src={FirstImage} width="146px" margin="-106px 0 0 0" />
              <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800"
                        margin="24px 0 0 0">
                знакомство
              </TextPage>
              <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center"
                        margin="8px 10px 0 10px">
                познакомимся и расскажем обо всех этапах открытия
              </TextPage>
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" width="358px" height="292px" backgroundColor="white"
                               borderRadius="24px" $justifyContent="center" $alignItems="center">
              <ImagePage src={SecondImage} width="146px" margin="-106px 0 0 0" />
              <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800"
                        margin="24px 0 0 0">
                договор
              </TextPage>
              <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center"
                        margin="8px 10px 0 10px">
                рассмотрим все юридические аспекты и подпишем договор
              </TextPage>
            </FlexContainerPage>
            <Grid gridTemplateColumns="repeat(1, 0fr)" gridRowGap="80px" gridColumnGap="40px" className={isOpen}>
            <FlexContainerPage flexDirection="column" width="358px" height="292px" backgroundColor="white"
                               borderRadius="24px" $justifyContent="center" $alignItems="center">
              <ImagePage src={ThirdImage} width="146px" margin="-106px 0 0 0" />
              <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800"
                        margin="24px 0 0 0">
                помещение
              </TextPage>
              <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center"
                        margin="8px 10px 0 10px">
                поможем с поиском помещения и арендодателями
              </TextPage>
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" width="358px" height="292px" backgroundColor="white"
                               borderRadius="24px" $justifyContent="center" $alignItems="center">
              <ImagePage src={FourthImage} width="146px" margin="-106px 0 0 0" />
              <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800"
                        margin="24px 0 0 0">
                проектирование
              </TextPage>
              <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center"
                        margin="8px 10px 0 10px">
                создадим технологический эскиз и дизайн-проект
              </TextPage>
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" width="358px" height="292px" backgroundColor="white"
                               borderRadius="24px" $justifyContent="center" $alignItems="center">
              <ImagePage src={FifthImage} width="146px" margin="-106px 0 0 0" />
              <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800"
                        margin="24px 0 0 0">
                строительство
              </TextPage>
              <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center"
                        margin="8px 10px 0 10px">
                поможем на всех этапах строительства и предоставим свою базу подрядчиков
              </TextPage>
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" width="358px" height="292px" backgroundColor="white"
                               borderRadius="24px" $justifyContent="center" $alignItems="center">
              <ImagePage src={SixthImage} width="146px" margin="-106px 0 0 0" />
              <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800"
                        margin="24px 0 0 0">
                обучение
              </TextPage>
              <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center"
                        margin="8px 10px 0 10px">
                обучим и аттестуем партнеров и сотрудников сети
              </TextPage>
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" width="358px" height="292px" backgroundColor="white"
                               borderRadius="24px" $justifyContent="center" $alignItems="center">
              <ImagePage src={SeventhImage} width="146px" margin="-106px 0 0 0" />
              <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800"
                        margin="24px 0 0 0">
                персонал
              </TextPage>
              <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center"
                        margin="8px 10px 0 10px">
                обучим и аттестуем партнеров и сотрудников сети
              </TextPage>
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" width="358px" height="292px" backgroundColor="white"
                               borderRadius="24px" $justifyContent="center" $alignItems="center">
              <ImagePage src={EightthImage} width="146px" margin="-106px 0 0 0" />
              <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800"
                        margin="24px 0 0 0">
                запуск
              </TextPage>
              <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center"
                        margin="8px 10px 0 10px">
                поможем организовать крутое праздничное открытие
              </TextPage>
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" width="358px" height="292px" backgroundColor="white"
                               borderRadius="24px" $justifyContent="center" $alignItems="center">
              <ImagePage src={NinethImage} width="146px" margin="-106px 0 0 0" />
              <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800"
                        margin="24px 0 0 0">
                сопровождение
              </TextPage>
              <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center"
                        margin="8px 10px 0 10px">
                поддержим 24/7: снабжение, консультации, поиск сотрудников, маркетинг
              </TextPage>
            </FlexContainerPage>


            </Grid>
            <Button color="red" $backgroundColor="white" border="2px solid red" borderRadius="12px" fontSize="18px" fontWeight="600" onClick={toggle}>
              смотреть все
            </Button>
          </Grid>
        </FlexContainerPage>
      </BlockPage>
    </HowDoWeWorkWithClientMobileBlockStyles>
  );
};

export default HowDoWeWorkWithClientMobileBlockPage;