import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import WhiteCircle from "../../../../img/Ellipse 111.png"
import TarTar from "../../../../img/тартар-тунец-клубника-newсайт (1)-восстановлено копия 1.png"
import Button from "../../../../components/Button/page/Button";
import OurProductBlockStyles from "../styles/OurProductBlockStyles";

const OurProductBlockPage = () => {

    return (
        <OurProductBlockStyles>
            <BlockPage $backgroundColor="#8ED8F7" height="1080px">
                <ImagePage src={WhiteCircle} position="absolute" zIndex={0} margin="86px 0 0 305px"/>
                <ImagePage src={TarTar} position="absolute" zIndex={1} margin="113px 0 0 43px"/>
                <FlexContainerPage top="233px" position="relative" zIndex={1}
                                   flexDirection="column" $justifyContent="flex-end" $alignItems="flex-end"
                                   >
                    <TextPage fontSize="100px" fontWeight="800" color="red" width="445px" lineHeight="118px"
                              position="relative" right="441px">
                        НАШ ПРОДУКТ
                    </TextPage>
                    <TextPage fontSize="36px" fontWeight="400" color="black" width="704px" lineHeight="43.2px"
                              position="relative" right="182px">
                        Уже 12 лет мы готовим вкуснейшие суши, роллы и воки в лучших традициях Азии.
                        <br/>
                        <br/>
                        Наш приоритет - качественный продукт, передовые технологии и нестандартный подход к своему делу
                    </TextPage>
                    <Button $backgroundColor="white" borderRadius="12px" width="370px"
                            height="56px" color="red" fontSize="24px" fontWeight="600"
                            border="2px solid red" position="relative" right="516px" margin="32px 0 0 0">
                        стать партнером
                    </Button>
                </FlexContainerPage>
            </BlockPage>
        </OurProductBlockStyles>
    );
};

export default OurProductBlockPage;