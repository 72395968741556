import CarouselMobileBlockProps from "../props/CarouselMobileBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";
import {StyledCarousel} from "../components/StyledCarousel";

const CarouselMobileBlockStyles = styled.div<CarouselMobileBlockProps>`
  
  position: relative;
  .carousel {
    display: flex;
    justify-content: center;
  }
  .WrapText {
    display: none;
  }
  .noWrapText {
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    .WrapText {
      display: flex;
      margin-top: 0;
    }
    .noWrapText {
      display: none;
    }
    .carouselText {
      margin-top: -50px;
    }
  }
  
  @media screen and (max-width: 390px) {
    .carouselText {
      margin-top: 50px;
    }
  }

 

`

export default CarouselMobileBlockStyles
