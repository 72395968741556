import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import Button from "../../../../components/Button/page/Button";
import OpenRestoranBlockMobileStyles from "../styles/OpenRestoranBlockMobileStyles";

import BigBack from '../media/Ellipse 101.png'
import SmallBack from "../media/Ellipse 100.png"
import Face from "../../../../img/Ellipse 95.png"
import WhiteLogo from "../../../../img/WhiteLogo.png";

const OpenRestoranBlockMobilePage = () => {

    return (
        <OpenRestoranBlockMobileStyles>
            <BlockPage width="100%">
                <ImagePage className="bigBack" src={BigBack} right="0" position="absolute" zIndex={1} width="930px" />
                <ImagePage className="smallBack" src={SmallBack} right="0" position="absolute" zIndex={1}/>
                <FlexContainerPage flexDirection="column" position="relative" zIndex={2}  padding="123px 0 0 17%">
                    <FlexContainerPage flexDirection="column" className="mainText">
                        <TextPage fontSize="84px" fontWeight="800" color="white" lineHeight="89.44px" width="358px">
                            ОТКРОЙ РЕСТОРАН ИЗ МИРА АЗИИ
                        </TextPage>
                        <TextPage fontSize="34px" fontWeight="600" color="white" lineHeight="36.4px" width="266px">
                            и зарабатывай
                            <TextPage fontSize="34px" fontWeight="900" color='white'> от</TextPage>
                            <TextPage fontSize="34px" fontWeight="900" color='red'> 300 000 руб.</TextPage>в месяц
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage $alignItems="center" margin="50px 0 0 0" className="bottomText" $justifyContent="flex-start" width="100%">
                        <TextPage fontSize="22px" lineHeight="24.4px" fontWeight="600" color="white" width="293px"  textAlign="right">
                            <TextPage fontSize="22px" lineHeight="24.4px" fontWeight="700" color="white" textAlign="right">
                                Юлия Бондаренко,
                            </TextPage>
                            помогу открыть франшизу
                            в твоём городе
                        </TextPage>
                        <ImagePage src={Face} margin="0 0 0 16px" width="77px"/>
                    </FlexContainerPage>
                    <Button margin="27px 0 0 0" width="360px" height="56px" fontSize="24px" color="red"
                            fontWeight='600' cursor='pointer' borderRadius="12px" $backgroundColor="white" border="2px solid red">
                        стать партнером
                    </Button>
                </FlexContainerPage>
                <BlockPage  height="711px" $backgroundColor="#FF0032" margin="-100px 0 0 0">
                <FlexContainerPage  flexDirection="column" position="relative" padding="400px 30px 0 30px" zIndex={2} $alignItems="center" className="footer">
                    <ImagePage src={WhiteLogo} maxWidth="358px"/>
                    <FlexContainerPage>
                        <TextPage lineHeight="38.8px" fontSize="34px" fontWeight="500"  height="174px" maxWidth="600px" color="white" margin="23px 0 0 0" textAlign="center">
                            – это сеть ресторанов японской и паназиатской кухни, созданная людьми, которые любят вкус
                        </TextPage>
                    </FlexContainerPage>

                </FlexContainerPage>
            </BlockPage>
            </BlockPage>
        </OpenRestoranBlockMobileStyles>
    );
};

export default OpenRestoranBlockMobilePage;