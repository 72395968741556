import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import OpenTheWorldOfAsiaMobileBlockStyles from "../styles/OpenTheWorldOfAsiaMobileBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";


import Back1 from "../media/Ellipse 80.png"
import Back2 from "../media/Ellipse 2.png"
import Back3 from "../media/Ellipse 1.png"
import Back4 from "../media/Ellipse 11.png"
import Back5 from "../media/Ellipse 12.png"
import Button from "../../../../components/Button/page/Button";

import CircleOpacity from "../media/Кружочек прозрачный.png"
import CircleCommon from "../media/Кружочек белый.png"
import Input from "../../../../components/Input/page/InputPage";

import Face from "../media/Group 336.png";
import Logo from "../media/Frame.png";

import Face1 from "../media/Intersect.png"
import Circle1 from "../media/Кружочек белый.png"
import Circle2 from "../media/Кружочек прозрачный.png"
import Circle3 from "../media/Ellipse 1 (1).png"





const OpenTheWorldOfAsiaMobileBlockPage = () => {

    return (
        <OpenTheWorldOfAsiaMobileBlockStyles>
            <BlockPage width="100%" $backgroundColor="white" height="1022px">

                <FlexContainerPage flexDirection="column" position="relative" zIndex={10}>
                    <FlexContainerPage flexDirection="column" margin="70px 0 0 16px" className="openWorldOfAsia">
                        <TextPage fontSize="60px" fontWeight="800" lineHeight="70.8px" color="darkRed" width="320px">
                            ОТКРОЙ
                            МИР АЗИИ
                            В СВОЁМ
                            ГОРОДЕ
                        </TextPage>
                    </FlexContainerPage>

                    <FlexContainerPage flexDirection="column" margin="55px 0 0 0" className="cities">
                        <FlexContainerPage flexDirection="column" position="absolute" $alignItems="center" $justifyContent="center" margin="0 0 0 31px">
                            <TextPage fontSize="15px" lineHeight="29.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Екатеринбург
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="75px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="44px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" position="absolute" $justifyContent="center" margin="112px 0 0 137px">
                            <TextPage fontSize="15px" lineHeight="29.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Ставрополь
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="76px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="43px"/>
                        </FlexContainerPage>

                        <FlexContainerPage $alignItems="center" position="absolute" $justifyContent="center" margin="204px 0 0 86px">
                            <TextPage fontSize="15px" lineHeight="29.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Москва
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="122px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="70px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" position="absolute" $justifyContent="center" margin="198px 0 0 213px">
                            <TextPage fontSize="15px" lineHeight="29.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Новосибирск
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="80px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="46px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" position="absolute" $justifyContent="center" margin="273px 0 0 207px">
                            <TextPage fontSize="15px" lineHeight="29.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Уфа
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="31px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="18px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" position="absolute" $justifyContent="center" margin="309px 0 0 305px">
                            <TextPage fontSize="15px" lineHeight="39.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Тюмень
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="80px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="46px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" position="absolute" $justifyContent="center" margin="328px 0 0 37px">
                            <TextPage fontSize="15px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} width="100px" textAlign="center">
                                Санкт-Петербург
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="81px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="47px"/>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" position="absolute" $justifyContent="center" margin="344px 0 0 171px">
                            <TextPage fontSize="15px" lineHeight="39.6px" fontWeight="600" color="red"
                                      position="relative" zIndex={6} textAlign="center">
                                Пермь
                            </TextPage>
                            <ImagePage src={CircleOpacity} position="absolute" zIndex={5} width="79px"/>
                            <ImagePage src={CircleCommon} position="absolute" zIndex={5} width="46px"/>
                        </FlexContainerPage>
                    </FlexContainerPage>
                </FlexContainerPage>
                <FlexContainerPage position="relative" className="bigBack">
                    <ImagePage src={Back2} position="absolute" zIndex={2} margin="-200px 0 0 -900px" />
                    <ImagePage src={Back3} position="absolute" zIndex={1} margin="-200px 0 0 -900px"/>
                </FlexContainerPage>
                <FlexContainerPage position="relative" margin="-200px 0 0 0" className="smallBack">
                    <ImagePage src={Back4} position="absolute" zIndex={2} />
                    <ImagePage src={Back5} position="absolute" zIndex={1}/>
                </FlexContainerPage>
                <FlexContainerPage flexDirection="column" position="relative" zIndex={10} margin="610px 16px 0 16px"
                                   $alignItems="left" $justifyContent="center" rowGap="16px" className="inputBottom" >
                    <TextPage fontSize="24px" fontWeight="600" lineHeight="28.8px" maxWidth="300px" color="white">
                        узнай, как открыть
                        франшизу в своём городе
                    </TextPage>
                    <Input placeholder="email" width="342px" height="56px" border="2px solid red"
                           borderRadius="12px" padding="0 0 0 24px" fontSize="18px" fontWeight="600" color="rgba(0, 0, 0, 0.3)"/>
                    <Button color="red" $backgroundColor="white" width="204px" height="56px" borderRadius="12px" fontSize="24px" fontWeight="600" border="2px solid red">
                        отправить
                    </Button>
                </FlexContainerPage>

            </BlockPage>
            <BlockPage $backgroundColor="#FF0032" height="703px" position="relative" zIndex="20">
                <FlexContainerPage flexDirection="column" className="bottomBlock" padding="46px 0 0 16px" position="relative" zIndex={2}>
                    <ImagePage src={Logo} width="171px" />
                    <TextPage fontSize="60px" lineHeight="70.8px" fontWeight="800" color="white" width="265px">
                        ЕВГЕНИЙ КУПКО
                    </TextPage>
                    <TextPage fontSize="24px" lineHeight="28.32px" fontWeight="800" color="white" width="128px">
                        основатель кампании
                    </TextPage>
                </FlexContainerPage>
                    <ImagePage src={Face} position="absolute" zIndex={1} margin="-128px 0 0 0" className="smallFace"/>
                <FlexContainerPage className="bigFace" $alignItems="center" position="relative" margin="210px 0 0 0" $justifyContent="center">
                    <ImagePage src={Circle3} position="absolute" width="600px"/>
                    <ImagePage src={Circle2} position="absolute" width="500px"/>
                    <ImagePage src={Circle1} position="absolute" width="400px"/>
                    <ImagePage src={Face1}  position="absolute" margin="10px 0 0 -35px"/>
                </FlexContainerPage>

            </BlockPage>
        </OpenTheWorldOfAsiaMobileBlockStyles>
    );
};

export default OpenTheWorldOfAsiaMobileBlockPage;