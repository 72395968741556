import styled from "styled-components";
import BlockProps from "../props/BlockProps";

export const BlockStyles = styled.div<BlockProps>`
  background-color: ${({$backgroundColor}) => $backgroundColor ? $backgroundColor : 'white'};
  width: ${({width}) => width ? width : '100%'};
  height: ${({height}) => height ? height : ""};
  margin: ${({margin}) => margin ? margin : ''};
  padding: ${({padding}) => padding ? padding : ''};
  top: ${({top}) => top ? top : ''};
  position:  ${({position}) => position ? position : ''};
  z-index: ${({zIndex}) => zIndex ? zIndex : ''};
`