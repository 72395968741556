import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import BecomeAPartnerBlockStyles from "../styles/BecomeAPartnerBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import Input from "../../../../components/Input/page/InputPage";
import Button from "../../../../components/Button/page/Button";
import { Helmet } from "react-helmet"
import { useEffect } from "react";
import useScript from "../hooks/useScript";



const BecomeAPartnerBlockPage = () => {
    const script = useScript()
    //@ts-ignore
    return <script data-b24-form="inline/45/u37j5r" data-skip-moving="true">{script}</script>
};

export default BecomeAPartnerBlockPage;