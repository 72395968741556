import FooterBlockProps from "../props/FooterBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";
import {StyledInput} from "../../../../components/Input/style/InputStyles";

const FooterBlockStyles = styled.div<FooterBlockProps>`
  position: relative;
  z-index: 101;
  margin: 105px 0 92px 0;
  border-top: 1px solid #E5E5E5;
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} {
      
    }


  }
  
  
  

`

export default FooterBlockStyles
