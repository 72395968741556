import BlubsWithTextBlockProps from "../props/BlubsWithTextBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";

const BlubsWithTextBlockStyles = styled.div<BlubsWithTextBlockProps>`
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} {
      width: 100%;
      height: 768px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledImage} {
      width: 900px;      
      margin: -150px 0 0 0;
    }
  }

  // @media screen and (max-width: 1700px) {
  //   & > ${BlockStyles}> ${StyledImage} {
  //     width: 860px;
  //     margin: -15% 0 0 0;
  //   }
  // }
  //
  // @media screen and (max-width: 1700px) {
  //   & > ${BlockStyles}> ${StyledImage} {
  //     width: 860px;
  //     margin: -15% 0 0 0;
  //   }
  // }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer} {
      width: 930px;
      padding: 50px 0 0 0;
      left: 60px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${ButtonStyles} {
      display: none;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer} {
      height: 300px;
      width: 435px;
      margin: 0 0 0 100px
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledImage}:nth-child(1) {
      height: 135px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledImage}:nth-child(2) {
      height: 266px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledImage}:nth-child(3) {
      height: 266px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles} {
      grid-column-gap: 26px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}:nth-child(3) {
      margin: 40px 0 0 0;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledImage}:nth-child(3) {
      width: 200px;
      margin: 130px 0 0 180px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledImage}:nth-child(2) {
      width: 120px;
      margin: 50px 0 0 -240px
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledImage} {
      width: 120px;
      margin: 0 0 0 600px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(1) {
      font-size: 36px;
      line-height: 42.48px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(2) {
      margin: 10px 0 0 0;
      width: 291px;
      font-size: 20px;
      line-height: 24px;
    }
  }
`

export default BlubsWithTextBlockStyles
