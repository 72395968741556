import HowDoWeWorkWithClientMobileBlockProps from "../props/HowDoWeWorkWithClientMobileBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";

const HowDoWeWorkWithClientMobileBlockStyles = styled.div<HowDoWeWorkWithClientMobileBlockProps>`
  position: relative;
  z-index: 6;
  
  .closed {
    display: none;
  }
  
  .open {
    display: grid;    
  }
  
  @media screen and (max-width: 390px) {
    .topText> ${StyledText} {
      text-align: left;
    }
  }

`

export default HowDoWeWorkWithClientMobileBlockStyles
