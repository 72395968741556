import {StyledDesktopHeader} from "../styles/DesktopHeaderStyles";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import LogoPage from "../../../../components/Logo/page/LogoPage";
import logo from "../../../../img/logo.png"
import TextPage from "../../../../components/Text/page/TextPage";
import Button from "../../../../components/Button/page/Button";

import AnchorLink from 'react-anchor-link-smooth-scroll'

const DesktopHeaderPage = () => {
    return (
        <StyledDesktopHeader>
            <FlexContainerPage
                width='calc(100% - 120px)'
                $justifyContent="space-between"
                height='90px'
                $alignItems='center'
                margin='0 auto'
            >
                <FlexContainerPage   width='min-content'>
                    <LogoPage src={logo} width='100%' margin='3% 0 0 0'/>
                </FlexContainerPage>
                <FlexContainerPage >
                    <AnchorLink href="#about" offset="130" className="link">
                        <TextPage hoverColor='lightBlue' margin="0 0 0 40px" padding="20px" fontSize="18px" fontWeight='bold' cursor='pointer'>
                            о нас
                        </TextPage>
                    </AnchorLink>
                    <AnchorLink href="#strengths" offset="50" className="link">
                        <TextPage hoverColor='lightBlue' margin="0 0 0 40px" padding="20px" fontSize="18px" fontWeight='bold' cursor='pointer'>
                            преимущества
                        </TextPage>
                    </AnchorLink>
                    <AnchorLink href="#formats" offset="250" className="link">
                        <TextPage hoverColor='lightBlue' margin="0 0 0 40px" padding="20px" fontSize="18px" fontWeight='bold' cursor='pointer'>
                            форматы
                        </TextPage>
                    </AnchorLink>
                    <AnchorLink href="#open" offset="50" className="link">
                        <TextPage hoverColor='lightBlue' margin="0 0 0 40px" padding="20px" fontSize="18px" fontWeight='bold' cursor='pointer'>
                            этапы открытия
                        </TextPage>
                    </AnchorLink>
                    <Button  margin="5px 0 0 40px" padding="16px 45px 16px 45px" fontSize="18px" color="white" fontWeight='Semi Bold' cursor='pointer' borderRadius="12px" $backgroundColor="red">
                        Стать партнером
                    </Button>
                </FlexContainerPage>

            </FlexContainerPage>
        </StyledDesktopHeader>
    );
};

export default DesktopHeaderPage;