import styled from "styled-components";
import DesktopHeaderProps from "../props/DesktopHeaderProps";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledLogo} from "../../../../components/Logo/styles/LogoStyles";

export const StyledDesktopHeader = styled.header<DesktopHeaderProps>`
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: white;
  position: fixed;
  z-index: 999;
  border-bottom: 1px solid #E5E5E5;

  .link {
    text-decoration: none;
  }


  @media screen and (max-width: 1280px) {
    & > ${StyledFlexContainer}> ${StyledFlexContainer} > ${StyledText} {
      font-size: 16px;
      margin-left: 24px;
      padding: 20px 0 0 0;
    }
  }
  @media screen and (max-width: 1280px) {
    & > ${StyledFlexContainer}> ${StyledFlexContainer} > ${StyledButton} {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 1280px) {
    & > ${StyledFlexContainer}> ${StyledFlexContainer} > ${StyledLogo} {
      width: 168px;
    }
  }

`


