import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import CarouselMobileBlockStyles from "../styles/CarouselMobileBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import Button from "../../../../components/Button/page/Button";

import AllDayImg from "../media/allDay.png"
import PrevArrow from "../media/forward.png"
import NextArrow from "../media/backward.png"

import Leaf from "../media/Монтера 1.png"
import LeafShadow from "../media/Монтера 2.png"

import Iphone from "../media/iPhone 12 Mini.png"
import Iphone1 from "../media/iPhone 12 Mini1.png"
import Iphone2 from "../media/iPhone 12 Mini2.png"
import Iphone3 from "../media/iPhone 12 Mini3.png"
import Iphone4 from "../media/iPhone 12 Mini4.png"
import Mac from "../media/MacBook Pro.png"


import Apple from "../media/apple.png"
import Google from "../media/google.png"

import WhiteLogo from "../media/whiteLogo.svg"

import BlueBack from "../media/Ellipse 106.png"


import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {StyledCarousel} from "../components/StyledCarousel";


const CarouselMobileBlockPage = () => {
    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 50,
        height: 30,
        cursor: 'pointer',
        border: "none",
        backgroundColor: "rgba(0, 0, 0, 0)"
    };

    const indicatorStyles = {
        background: 'grey',
        width: 14,
        height: 14,
        display: 'inline-block',
        margin: '230px 8px',
        borderRadius: "8px",


    };


    //TODO: Увеличить карсуль в размерах при увеличении размера экрана

    return (
        <CarouselMobileBlockStyles>
            <BlockPage width="100%" height="1085px" $backgroundColor="white">
                <FlexContainerPage position="relative" zIndex={5} flexDirection="column" margin="0 0 0 20px" className="noWrapText">
                    <TextPage fontSize="64px" maxWidth="500px" color="white" fontWeight="800" lineHeight="75.52px">
                        СОПРОВОЖДЕНИЕ ПАРТНЕРА
                    </TextPage>
                    <ImagePage src={AllDayImg} margin="60px 0 0 330px" position="absolute"/>
                </FlexContainerPage>
                <FlexContainerPage position="relative" zIndex={5} flexDirection="column" margin="0 0 0 20px" className="WrapText">
                    <TextPage fontSize="64px" maxWidth="500px" color="white" fontWeight="800" lineHeight="75.52px">
                        СОПРО-
                        <br/>
                        ВОЖДЕНИЕ
                        ПАРТНЕРА
                    </TextPage>
                    <ImagePage src={AllDayImg} margin="360px 0 0 240px" position="absolute"/>
                </FlexContainerPage>
                <FlexContainerPage position="relative" zIndex={10} width="100%" $justifyContent="center" $alignItems="center" margin="0 0 0 0" height="1000px">
                    <StyledCarousel
                        animationHandler="slide"
                        infiniteLoop
                        showThumbs={false}
                        showStatus={false}
                        emulateTouch
                        stopOnHover
                        showIndicators={false}
                        preventMovementUntilSwipeScrollTolerance
                        swipeScrollTolerance={20}
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <button type="button" onClick={onClickHandler} title={label}
                                    //@ts-ignore
                                        style={{ ...arrowStyles, right: 0,marginTop: "230px", position:"absolute", zIndex: 10 }}>
                                    <ImagePage src={PrevArrow}/>
                                </button>
                            )
                        }
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (

                                <button type="button" onClick={onClickHandler} title={label}
                                    //@ts-ignore
                                        style={{ ...arrowStyles, right: 56, marginTop: "230px" }}>
                                    <ImagePage src={NextArrow}/>
                                </button>
                            )
                        }

                    >
                        <FlexContainerPage flexDirection="column" $alignItems="center"  $justifyContent="center" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" position="absolute" top="50px" left="16px" className="carouselText">
                                <TextPage fontSize="36px" fontWeight="800" textAlign="left" color="white" lineHeight="42.48px">
                                    мобильное
                                    приложение
                                </TextPage>

                            </FlexContainerPage>
                            <FlexContainerPage $justifyContent="center" $alignItems="center">
                                <ImagePage src={Leaf} position="absolute" maxWidth="500px"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="500px" />
                                <ImagePage src={Iphone} position="absolute" maxWidth="200px" />
                            </FlexContainerPage>
                            <TextPage width="236px" fontSize="16px" margin="500px 0 0 0" fontWeight="600" textAlign="left" position="absolute" left="16px"  color="black" lineHeight="19.2px">
                                помогает гостям быстро
                                выбрать и заказать
                                наш продукт
                            </TextPage>
                            <FlexContainerPage margin="802px 0 0 0" flexDirection="column" rowGap="13px" position="absolute" left="16px">
                                <Button width="320px" border="2px solid red" color="red" $backgroundColor="white"
                                        padding="15px 29px 15px 29px" borderRadius="12px" fontWeight="600" fontSize="24px" margin="0 16px 0 0" cursor="pointer">
                                    <ImagePage src={Apple} maxWidth="24px" margin="3px 14px 0 0"/>
                                    скачать для iOS
                                </Button>
                                <Button width="320px" border="2px solid red" color="red" $backgroundColor="white"
                                        padding="15px 29px 15px 29px" borderRadius="12px" fontWeight="600" fontSize="24px" cursor="pointer">
                                    <ImagePage src={Google} maxWidth="14px" margin="6px 14px 0 0"/>
                                    скачать для android
                                </Button>
                            </FlexContainerPage>
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $alignItems="center"  $justifyContent="center" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" position="absolute" top="50px" left="16px" className="carouselText">
                                <TextPage fontSize="36px" fontWeight="800" textAlign="left" color="white" lineHeight="42.48px" width="250px">
                                    приложение
                                    для курьера
                                </TextPage>

                            </FlexContainerPage>
                            <FlexContainerPage $justifyContent="center" $alignItems="center">
                                <ImagePage src={Leaf} position="absolute" maxWidth="500px"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="500px" />
                                <ImagePage src={Iphone1} position="absolute" maxWidth="200px" />
                            </FlexContainerPage>
                            <TextPage width="236px" fontSize="16px" margin="500px 0 0 0" fontWeight="600" textAlign="left" position="absolute" left="16px"  color="black" lineHeight="19.2px">
                                помогает сократить время доставки заказа от ресторана до гостя
                            </TextPage>
                            <FlexContainerPage margin="802px 0 0 0" flexDirection="column" rowGap="13px" position="absolute" left="16px">
                                <Button width="320px" border="2px solid red" color="red" $backgroundColor="white"
                                        padding="15px 29px 15px 29px" borderRadius="12px" fontWeight="600" fontSize="24px" margin="0 16px 0 0" cursor="pointer">
                                    <ImagePage src={Apple} maxWidth="24px" margin="3px 14px 0 0"/>
                                    скачать для iOS
                                </Button>
                                <Button width="320px" border="2px solid red" color="red" $backgroundColor="white"
                                        padding="15px 29px 15px 29px" borderRadius="12px" fontWeight="600" fontSize="24px" cursor="pointer">
                                    <ImagePage src={Google} maxWidth="14px" margin="6px 14px 0 0"/>
                                    скачать для android
                                </Button>
                            </FlexContainerPage>
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $alignItems="center"  $justifyContent="center" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" position="absolute" top="50px" left="16px" className="carouselText">
                                <TextPage fontSize="36px" fontWeight="800" textAlign="left" color="white" lineHeight="42.48px" width="200px">
                                    сушивесла академия
                                </TextPage>

                            </FlexContainerPage>
                            <FlexContainerPage $justifyContent="center" $alignItems="center">
                                <ImagePage src={Leaf} position="absolute" maxWidth="500px"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="500px" />
                                <ImagePage src={Iphone2} position="absolute" maxWidth="200px" />
                            </FlexContainerPage>
                            <TextPage width="236px" fontSize="16px" margin="500px 0 0 0" fontWeight="600" textAlign="left" position="absolute" left="16px"  color="black" lineHeight="19.2px">
                                помогает повышать уровень квалификации сотрудников
                                в режиме онлайн
                            </TextPage>
                            <FlexContainerPage margin="802px 0 0 0" flexDirection="column" rowGap="13px" position="absolute" left="16px">
                                <Button width="320px" border="2px solid red" color="red" $backgroundColor="white"
                                        padding="15px 29px 15px 29px" borderRadius="12px" fontWeight="600" fontSize="24px" margin="0 16px 0 0" cursor="pointer">
                                    <ImagePage src={Apple} maxWidth="24px" margin="3px 14px 0 0"/>
                                    скачать для iOS
                                </Button>
                                <Button width="320px" border="2px solid red" color="red" $backgroundColor="white"
                                        padding="15px 29px 15px 29px" borderRadius="12px" fontWeight="600" fontSize="24px" cursor="pointer">
                                    <ImagePage src={Google} maxWidth="14px" margin="6px 14px 0 0"/>
                                    скачать для android
                                </Button>
                            </FlexContainerPage>
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $alignItems="center"  $justifyContent="center" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" position="absolute" top="50px" left="16px" className="carouselText">
                                <TextPage fontSize="36px" fontWeight="800" textAlign="left" color="white" lineHeight="42.48px" width="200px">
                                    сушивесла аналитика
                                </TextPage>

                            </FlexContainerPage>
                            <FlexContainerPage $justifyContent="center" $alignItems="center">
                                <ImagePage src={Leaf} position="absolute" maxWidth="500px"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="500px" />
                                <ImagePage src={Iphone3} position="absolute" maxWidth="200px" />
                            </FlexContainerPage>
                            <TextPage width="236px" fontSize="16px" margin="500px 0 0 0" fontWeight="600" textAlign="left" position="absolute" left="16px"  color="black" lineHeight="19.2px">
                                помогает отслеживать ключевые показатели
                                бизнеса 24/7
                            </TextPage>
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $alignItems="center"  $justifyContent="center" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" position="absolute" top="50px" left="16px" className="carouselText">
                                <TextPage fontSize="36px" fontWeight="800" textAlign="left" color="white" lineHeight="42.48px" width="200px">
                                    видео-
                                    контроль
                                </TextPage>

                            </FlexContainerPage>
                            <FlexContainerPage $justifyContent="center" $alignItems="center">
                                <ImagePage src={Leaf} position="absolute" maxWidth="500px"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="500px" />
                                <ImagePage src={Iphone4} position="absolute" maxWidth="200px" />
                            </FlexContainerPage>
                            <TextPage width="236px" fontSize="16px" margin="500px 0 0 0" fontWeight="600" textAlign="left" position="absolute" left="16px"  color="black" lineHeight="19.2px">
                                помогает контролировать качество продукта на каждом этапе
                            </TextPage>
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $alignItems="center"  $justifyContent="center" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" position="absolute" top="50px" left="16px" className="carouselText">
                                <TextPage fontSize="36px" fontWeight="800" textAlign="left" color="white" lineHeight="42.48px" width="200px">
                                    дизайн проект
                                </TextPage>

                            </FlexContainerPage>
                            <FlexContainerPage $justifyContent="center" $alignItems="center">
                                <ImagePage src={Leaf} position="absolute" maxWidth="500px"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="500px" />
                                <ImagePage src={Mac} position="absolute" maxWidth="300px" />
                            </FlexContainerPage>
                            <TextPage width="236px" fontSize="16px" margin="500px 0 0 0" fontWeight="600" textAlign="left" position="absolute" left="16px"  color="black" lineHeight="19.2px">
                                помогает реализовывать более чем 12-летний опыт компании в новых ресторанах
                            </TextPage>
                        </FlexContainerPage>

                    </StyledCarousel>
                </FlexContainerPage>
                    <ImagePage src={BlueBack} position="absolute" zIndex={7} className="blueBack"  margin="-540px 0 0 0"/>
                <FlexContainerPage position="absolute" zIndex={7} height="582px" width="62%" right="0" backgroundColor="#8ED8F7"  margin="-540px 0 0 38%">

                </FlexContainerPage>
            </BlockPage>
            <BlockPage $backgroundColor="#FF0032">
                <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center" margin="100px 0 0 0" padding="150px 0 100px 0">
                    <ImagePage src={WhiteLogo} maxWidth="380px"/>
                    <FlexContainerPage>
                        <TextPage lineHeight="28.8px" fontSize="24px" fontWeight="600" color="white" textAlign="center" margin="16px 20px 0 20px">
                            – это сеть ресторанов японской и паназиатской кухни, созданная людьми, которые любят вкус,
                            для людей, которые его ценят
                        </TextPage>
                    </FlexContainerPage>

                </FlexContainerPage>


            </BlockPage>
        </CarouselMobileBlockStyles>
    );
};

export default CarouselMobileBlockPage;