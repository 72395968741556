import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import CarouselBlockStyles from "../styles/CarouselBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import Button from "../../../../components/Button/page/Button";

import AllDayImg from "../media/Group 265.png"
import PrevArrow from "../media/Ellipse 111.png"
import NextArrow from "../media/Ellipse 112.png"

import Iphone from "../media/iPhone 12 Mini.png"
import Iphone2 from "../media/iPhone 12 Mini2.png"
import Iphone3 from "../media/iPhone 12 Mini3.png"
import Iphone4 from "../media/iPhone 12 Mini4.png"
import Iphone5 from "../media/iPhone 12 Mini5.png"
import Mac from "../media/MacBook Pro.png"
import Leaf from "../media/Монтера 1.png"
import LeafShadow from "../media/Монтера 2.png"
import Back from "../media/Ellipse 74.png"
import Apple from "../media/Vector1.png"
import Google from "../media/Frame.png"

import WhiteLogo from "../media/WhiteLogo.png"



import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {StyledCarousel} from "../components/StyledCarousel";


const CarouselBlockPage = () => {
    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 50,
        height: 30,
        cursor: 'pointer',
        border: "none",
        backgroundColor: "rgba(0, 0, 0, 0)"
    };

    const indicatorStyles = {
        background: 'grey',
        width: 14,
        height: 14,
        display: 'inline-block',
        margin: '230px 8px',
        borderRadius: "8px",


    };




    return (
        <CarouselBlockStyles>
            <BlockPage width="100%" height="1085px" $backgroundColor="white">
                <FlexContainerPage position="relative" zIndex={5} flexDirection="column" margin="104px 0 0 211px">
                    <TextPage fontSize="100px" width="900px" color="white" fontWeight="800" lineHeight="118px">
                        СОПРОВОЖДЕНИЕ ПАРТНЕРА
                    </TextPage>
                    <ImagePage src={AllDayImg} margin="-130px 0 0 500px"/>
                </FlexContainerPage>
                <FlexContainerPage position="relative" zIndex={0} width="100%" $justifyContent="center" $alignItems="center" margin="-300px 0 0 0">
                    <StyledCarousel
                        animationHandler="slide"
                        infiniteLoop
                        showThumbs={false}
                        showStatus={false}
                        emulateTouch
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <button type="button" onClick={onClickHandler} title={label}
                                    //@ts-ignore
                                        style={{ ...arrowStyles, right: 0, position:"absolute", zIndex: 10 }}>
                                    <ImagePage src={PrevArrow}/>
                                </button>
                            )
                        }
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (

                                <button type="button" onClick={onClickHandler} title={label}
                                    //@ts-ignore
                                        style={{ ...arrowStyles, left: 0 }}>
                                    <ImagePage src={NextArrow}/>
                                </button>
                            )
                        }

                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                            if (isSelected) {
                                return (
                                    <li
                                        style={{ ...indicatorStyles, backgroundColor: "red" }}
                                        aria-label={`Selected: ${label} ${index + 1}`}
                                        title={`Selected: ${label} ${index + 1}`}
                                        className={"selected"}
                                    />
                                );
                            }
                            return (
                                <li
                                    style={indicatorStyles}
                                    onClick={onClickHandler}
                                    onKeyDown={onClickHandler}
                                    value={index}
                                    key={index}
                                    role="button"
                                    tabIndex={0}
                                    title={`${label} ${index + 1}`}
                                    aria-label={`${label} ${index + 1}`}
                                />
                            );
                        }}
                    >
                        <Grid gridTemplateColumns="repeat(2, 1fr)" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" $justifyContent="center">
                                <TextPage width="570px" fontSize="72px" fontWeight="800" textAlign="left" color="red" lineHeight="84.96px">
                                    приложение
                                    сушивёсла
                                </TextPage>
                                <TextPage width="507px" fontSize="36px" fontWeight="600" textAlign="left" color="black" lineHeight="43.2px">
                                    помогает гостям быстро
                                    выбрать и заказать
                                    наш продукт
                                </TextPage>
                                <FlexContainerPage margin="32px 0 0 0">
                                    <Button width="320px" border="2px solid red" color="red" $backgroundColor="white"
                                            padding="15px 29px 15px 29px" borderRadius="12px" fontWeight="600" fontSize="24px" margin="0 16px 0 0" cursor="pointer">
                                        <ImagePage src={Apple} maxWidth="14px" margin="6px 14px 0 0"/>
                                        скачать для iOS
                                    </Button>
                                    <Button width="320px" border="2px solid red" color="red" $backgroundColor="white"
                                            padding="15px 29px 15px 29px" borderRadius="12px" fontWeight="600" fontSize="24px" cursor="pointer">
                                        <ImagePage src={Google} maxWidth="14px" margin="6px 14px 0 0"/>
                                        скачать для android
                                    </Button>
                                </FlexContainerPage>
                            </FlexContainerPage>
                            <FlexContainerPage height="1100px" $justifyContent="center" $alignItems="center">
                                <ImagePage src={Back} position="absolute" maxWidth="752px"/>
                                <ImagePage src={Leaf} position="absolute" maxWidth="1000px" margin="-70px -156px 37px 0"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="1000px" margin="0 0 -10px 100px"/>
                                <ImagePage src={Iphone} position="absolute" maxWidth="310px" margin="0 0 150px 0"/>
                            </FlexContainerPage>
                        </Grid>
                        <Grid gridTemplateColumns="repeat(2, 1fr)" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" $justifyContent="center">
                                <TextPage width="570px" fontSize="72px" fontWeight="800" textAlign="left" color="red" lineHeight="84.96px">
                                    приложение
                                    курьера
                                </TextPage>
                                <TextPage width="507px" fontSize="36px" fontWeight="600" textAlign="left" color="black" lineHeight="43.2px">
                                    помогает сократить время доставки заказа от ресторана
                                    до гостя
                                </TextPage>
                                <FlexContainerPage margin="32px 0 0 0">
                                    <Button width="320px" border="2px solid red" color="red" $backgroundColor="white"
                                            padding="15px 29px 15px 29px" borderRadius="12px" fontWeight="600" fontSize="24px" margin="0 16px 0 0" cursor="pointer">
                                        <ImagePage src={Apple} maxWidth="14px" margin="6px 14px 0 0"/>
                                        скачать для iOS
                                    </Button>
                                    <Button width="320px" border="2px solid red" color="red" $backgroundColor="white"
                                            padding="15px 29px 15px 29px" borderRadius="12px" fontWeight="600" fontSize="24px" cursor="pointer">
                                        <ImagePage src={Google} maxWidth="14px" margin="6px 14px 0 0"/>
                                        скачать для android
                                    </Button>
                                </FlexContainerPage>
                            </FlexContainerPage>
                            <FlexContainerPage height="1100px" $justifyContent="center" $alignItems="center">
                                <ImagePage src={Back} position="absolute" maxWidth="752px"/>
                                <ImagePage src={Leaf} position="absolute" maxWidth="1000px" margin="-70px -156px 37px 0"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="1000px" margin="0 0 -10px 100px"/>
                                <ImagePage src={Iphone2} position="absolute" maxWidth="310px" margin="0 0 150px 0"/>
                            </FlexContainerPage>
                        </Grid>
                        <Grid gridTemplateColumns="repeat(2, 1fr)" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" $justifyContent="center">
                                <TextPage width="570px" fontSize="72px" fontWeight="800" textAlign="left" color="red" lineHeight="84.96px">
                                    сушивёсла
                                    академия
                                </TextPage>
                                <TextPage width="507px" fontSize="36px" fontWeight="600" textAlign="left" color="black" lineHeight="43.2px">
                                    помогает повышать уровень квалификации сотрудников в режиме онлайн
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage height="1100px" $justifyContent="center" $alignItems="center">
                                <ImagePage src={Back} position="absolute" maxWidth="752px"/>
                                <ImagePage src={Leaf} position="absolute" maxWidth="1000px" margin="-70px -156px 37px 0"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="1000px" margin="0 0 -10px 100px"/>
                                <ImagePage src={Iphone3} position="absolute" maxWidth="310px" margin="0 0 150px 0"/>
                            </FlexContainerPage>
                        </Grid>
                        <Grid gridTemplateColumns="repeat(2, 1fr)" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" $justifyContent="center">
                                <TextPage width="570px" fontSize="72px" fontWeight="800" textAlign="left" color="red" lineHeight="84.96px">
                                    сушивесла аналитика
                                </TextPage>
                                <TextPage width="507px" fontSize="36px" fontWeight="600" textAlign="left" color="black" lineHeight="43.2px">
                                    помогает гостям быстро
                                    выбрать и заказать
                                    наш продукт
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage height="1100px" $justifyContent="center" $alignItems="center">
                                <ImagePage src={Back} position="absolute" maxWidth="752px"/>
                                <ImagePage src={Leaf} position="absolute" maxWidth="1000px" margin="-70px -156px 37px 0"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="1000px" margin="0 0 -10px 100px"/>
                                <ImagePage src={Iphone4} position="absolute" maxWidth="310px" margin="0 0 150px 0"/>
                            </FlexContainerPage>
                        </Grid>
                        <Grid gridTemplateColumns="repeat(2, 1fr)" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" $justifyContent="center">
                                <TextPage width="570px" fontSize="72px" fontWeight="800" textAlign="left" color="red" lineHeight="84.96px">
                                    Видео-
                                    <br/>
                                    контроль
                                </TextPage>
                                <TextPage width="507px" fontSize="36px" fontWeight="600" textAlign="left" color="black" lineHeight="43.2px">
                                    помогает контролировать качество продукта на каждом этапе
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage height="1100px" $justifyContent="center" $alignItems="center">
                                <ImagePage src={Back} position="absolute" maxWidth="752px"/>
                                <ImagePage src={Leaf} position="absolute" maxWidth="1000px" margin="-70px -156px 37px 0"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="1000px" margin="0 0 -10px 100px"/>
                                <ImagePage src={Iphone5} position="absolute" maxWidth="310px" margin="0 0 150px 0"/>
                            </FlexContainerPage>
                        </Grid>
                        <Grid gridTemplateColumns="repeat(2, 1fr)" padding="0 100px 0 100px">
                            <FlexContainerPage flexDirection="column" $justifyContent="center">
                                <TextPage width="570px" fontSize="72px" fontWeight="800" textAlign="left" color="red" lineHeight="84.96px">
                                    Дизайн
                                    проект
                                </TextPage>
                                <TextPage width="507px" fontSize="36px" fontWeight="600" textAlign="left" color="black" lineHeight="43.2px">
                                    помогает реализовывать более чем 12-летний опыт компании в новых ресторанах
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage height="1100px" $justifyContent="center" $alignItems="center">
                                <ImagePage src={Back} position="absolute" maxWidth="752px"/>
                                <ImagePage src={Leaf} position="absolute" maxWidth="1000px" margin="-70px -156px 37px 0"/>
                                <ImagePage src={LeafShadow} position="absolute" maxWidth="1000px" margin="0 0 -10px 100px"/>
                                <ImagePage src={Mac} position="absolute" maxWidth="860px" margin="0 0 150px 0" className={"Mac"} />
                            </FlexContainerPage>
                        </Grid>

                    </StyledCarousel>
                </FlexContainerPage>

            </BlockPage>
            <BlockPage  height="232px" $backgroundColor="#FF0032">
                <FlexContainerPage flexDirection="row" $alignItems="center" $justifyContent="center" padding="50px 0 0 0">
                    <ImagePage src={WhiteLogo} width="421px"/>
                    <FlexContainerPage>
                        <TextPage lineHeight="43.2px" fontSize="36px" fontWeight="600" color="white" width="850px" margin="0 0 0 40px">
                            – это сеть ресторанов японской и паназиатской кухни, созданная людьми, которые любят вкус,
                            для людей, которые его ценят
                        </TextPage>
                    </FlexContainerPage>

                </FlexContainerPage>
            </BlockPage>
        </CarouselBlockStyles>
    );
};

export default CarouselBlockPage;