import styled from "styled-components";
import SushiVeslaTodayProps from "../props/SushiVeslaTodayProps";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";

const SushiVeslaTodayStyles = styled.div<SushiVeslaTodayProps>`
  
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}{
      height: 768px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer} {
      margin: 96px 25px 0 25px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledText} {
      font-size: 64px;
      padding: 18px 34px 18px 34px;
      border-radius: 24px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer} {
      margin: -105px 0 0 0;
      width: 904px;
      height: 548px;
      border-radius: 64px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${GridStyles} {
      margin: 130px 0 0 0;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(1) {
      font-size: 48px;
      line-height: 56.64px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(2) {
      font-size: 24px;
      line-height: 28.8px;
    }
  }

`

export default SushiVeslaTodayStyles