import styled from "styled-components";
import {Carousel} from "react-responsive-carousel";

export const StyledCarousel = styled(Carousel)`
    max-width: 390px;
    
    &> .carousel {
      display: flex;
      justify-content: center;
      height: 950px;
    }
    &>.carousel> .slider-wrapper {
      height: 100%;
      display: flex;
    }
    &>.carousel> .slider-wrapper>.slider> .slide {
      display: flex;
      justify-content: center;
    }
`