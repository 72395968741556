import ImageProps from "../props/ImageProps";
import styled from "styled-components";

export const StyledImage = styled.img<ImageProps>`
    width: ${({width}) => width ? width : ''};
    margin: ${({margin}) => margin ? margin : ''};
    min-width:  ${({minWidth}) => minWidth ? minWidth : ''};
    position: ${({position}) => position ? position : ''};
    z-index: ${({zIndex}) => zIndex ? zIndex : ''};
    top: ${({top}) => top ? top : ''};
    right: ${({right}) => right ? right : ''};
    left: ${({left}) => left ? left : ''};
    bottom: ${({bottom}) => bottom ? bottom : ''};
    max-width: ${({maxWidth}) => maxWidth ? maxWidth : ''};
`
