import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import RestaurantFormatsBlockStyles from "../styles/RestaurantFormatsBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

import Logo from "../../../../img/Frame1.svg"

import FirstFormat from "../../../../img/Rectangle 349.png"
import SecondFormat from "../../../../img/Rectangle 350.png"
import ThirdFormat from "../../../../img/Rectangle 351.png"
import FourthFormat from "../../../../img/Rectangle 352.png"

import Circle from "../media/Ellipse 2.png"
import Button from "../../../../components/Button/page/Button";
import DarkKitchenModalWindow from "../components/DarkKitchenModalWindow";
import { useState } from "react";


const RestaurantFormatsBlockPage = (props:any) => {

    const [showDarkKinchenModal, setShowDarkKinchenModal] = useState(false);

    const openDarkKinchenModal = () => {
        setShowDarkKinchenModal(prev => !prev);
    };


    return (
        <RestaurantFormatsBlockStyles>
            <BlockPage width="100%" height="1080px" id="formats">
                <Grid gridTemplateColumns="repeat(2, 1fr)" margin="248px 0 0 214px" position="relative" zIndex={3}>
                    <FlexContainerPage flexDirection="column" width="753px" height="474px">
                        <ImagePage src={Logo} width="338px"/>
                        <TextPage fontSize="100px" fontWeight="800" lineHeight="118px" color="red">
                            ФОРМАТЫ РЕСТОРАНОВ
                        </TextPage>
                        <TextPage fontSize="36px" fontWeight="600" lineHeight="43.2px" color="black" width="640px">
                            какой формат выберешь для успешного старта?
                        </TextPage>
                        <Button width="370px" color="red" $backgroundColor="white" fontWeight="600" fontSize="24px" border="2px solid red" borderRadius="12px" margin="32px 0 0 0">
                            стать партнером
                        </Button>
                    </FlexContainerPage>
                    <Grid width="864px" gridTemplateColumns="repeat(2, 1fr)" gridRowGap="60px" margin="-90px 0 0 -90px">
                        <FlexContainerPage $alignItems="center" $justifyContent="center" onClick={props.showDarkKitchen}  cursor="pointer" >
                            <ImagePage src={FirstFormat} zIndex={2}/>
                            <ImagePage src={Circle} position="absolute" zIndex={1} className="pulse"/>
                            <TextPage fontSize="36px" lineHeight="43.2px" fontWeight="600" position="absolute" zIndex={3} color="white">
                                DARK KITCHEN
                            </TextPage>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" cursor="pointer" onClick={props.showFoodCort} >
                            <ImagePage src={SecondFormat}/>
                            <TextPage fontSize="36px" lineHeight="43.2px" fontWeight="600" position="absolute" color="white" >
                                ФУД-КОРТ
                            </TextPage>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" cursor="pointer" onClick={props.showStreet}>
                            <ImagePage src={ThirdFormat}/>
                            <TextPage fontSize="36px" lineHeight="43.2px" fontWeight="600" position="absolute" color="white" >
                                СТРИТ
                            </TextPage>
                        </FlexContainerPage>
                        <FlexContainerPage $alignItems="center" $justifyContent="center" cursor="pointer" onClick={props.showBoutique}>
                            <ImagePage src={FourthFormat}/>
                            <TextPage fontSize="36px" lineHeight="43.2px" fontWeight="600" position="absolute" color="white">
                                БУТИК
                            </TextPage>
                        </FlexContainerPage>


                    </Grid>
                </Grid>
            </BlockPage>
        </RestaurantFormatsBlockStyles>
    );
};

export default RestaurantFormatsBlockPage;