import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import BlubsWithTextBlockStyles from "../styles/BlubsWithTextBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import Button from "../../../../components/Button/page/Button";

import BackLayer from "../media/ПРОЗРАЧНЫЕ УСЛОВИЯ.png"
import MaskLayer from "../media/Mask Group.png"
import DropLayer from "../media/Vector 1.png"
import Background from "../media/Ellipse 103.png"

import FirstDrop from "../media/Vector 2.png"
import SecondDrop from "../media/Vector 2 (1).png"
import ThirdDrop from "../media/Vector 2 (2).png"





const BlubsWithTextBlockPage = () => {

    return (
        <BlubsWithTextBlockStyles>
            <BlockPage width="100%" height="1244px" $backgroundColor="#8ED8F7">
                <ImagePage src={Background} position="absolute" zIndex={1} margin="-258px 0 0 0"/>
                <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center" position="relative" zIndex={5} padding="155px 0 0 0" margin="">
                    <FlexContainerPage $alignItems="center" $justifyContent="center" height="352px" margin="0 0 0 200px">
                        <ImagePage src={BackLayer} position="absolute" zIndex={2}/>
                        <ImagePage src={MaskLayer} position="absolute" zIndex={3} margin="-30px 0 0 -20px"/>
                        <ImagePage src={DropLayer} position="absolute" zIndex={4} margin="-30px 0 0 -20px"/>
                    </FlexContainerPage>
                    <Grid position="relative" zIndex={4} margin="57px 0 0 0" gridTemplateColumns="repeat(3, 1fr)" gridColumnGap="90px">
                        <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center">
                            <TextPage fontSize="64px" lineHeight="75.52px" fontWeight="800" color="white">
                                окупаемость
                            </TextPage>
                            <TextPage fontSize="24px" lineHeight="28.8px" fontWeight="800" color="white" width="420px" textAlign="center">
                                средний срок окупаемости ресторана - 15 месяцев
                            </TextPage>
                            <ImagePage src={FirstDrop} position="absolute" zIndex={2} margin="-200px 0 0 385px"/>
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center">
                            <TextPage fontSize="64px" lineHeight="75.52px" fontWeight="800" color="white">
                                первый взнос
                            </TextPage>
                            <TextPage fontSize="24px" lineHeight="28.8px" fontWeight="800" color="white" width="420px" textAlign="center">
                                первоначальный взнос
                                от 500 000 рублей
                            </TextPage>
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center">
                            <TextPage fontSize="64px" lineHeight="75.52px" fontWeight="800" color="white">
                                прибыль
                            </TextPage>
                            <TextPage fontSize="24px" lineHeight="28.8px" fontWeight="800" color="white" width="420px" textAlign="center">
                                финансовый результат
                                от 18% до 20%
                            </TextPage>
                        </FlexContainerPage>
                    </Grid>

                    <Grid position="relative" zIndex={4} margin="100px 0 0 0" gridTemplateColumns="repeat(2, 1fr)" gridColumnGap="90px">
                        <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center">
                            <TextPage fontSize="64px" lineHeight="75.52px" fontWeight="800" color="white">
                                инвестиции
                            </TextPage>
                            <TextPage fontSize="24px" lineHeight="28.8px" fontWeight="800" color="white" width="420px" textAlign="center">
                                общие инвестиции
                                от 4 000 000 рублей
                            </TextPage>
                        </FlexContainerPage>
                        <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center">
                            <TextPage fontSize="64px" lineHeight="75.52px" fontWeight="800" color="white">
                                роялти
                            </TextPage>
                            <ImagePage src={SecondDrop} position="absolute" zIndex={2} margin="-35px 0 0 -260px"/>
                            <TextPage fontSize="24px" lineHeight="28.8px" fontWeight="800" color="white" width="350px" textAlign="center">
                                низкая процентная ставка -
                                до 5%
                            </TextPage>
                        </FlexContainerPage>
                    </Grid>
                    <Button color="red" $backgroundColor="white" width="370px" borderRadius="12px" margin="48px 0 0 0" height="56px" fontWeight="600" fontSize="24px">
                        стать партнером
                    </Button>
                    <ImagePage src={ThirdDrop} margin="-25px 0 0 950px"/>
                </FlexContainerPage>
            </BlockPage>
        </BlubsWithTextBlockStyles>
    );
};

export default BlubsWithTextBlockPage;