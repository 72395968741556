import OpenRestoranBlockMobileProps from "../props/OpenRestoranBlockMobileProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";

const OpenRestoranBlockMobileStyles = styled.div<OpenRestoranBlockMobileProps>`
  .smallBack {
    display: none;
  }
  @media screen and (max-width: 430px) {
    .bigBack {
      display: none;
    }
    .smallBack {
      display: flex;
      height: 930px;
    }
    .footer {
      padding: 380px 30px 0 30px;
    }
  }
 

  @media screen and (max-width: 520px) {
    .bigBack {
      height: 900px;
    }

    &> ${BlockStyles}> ${StyledFlexContainer} {
      padding: 123px 0 0 16px;
    }
    
    .mainText> ${StyledText}:nth-child(1) {
      font-size: 64px;
      line-height: 69.44px;
    }
    .mainText> ${StyledText}:nth-child(2) {
      font-size: 24px;
      line-height: 26.4px;
    }
    .mainText> ${StyledText}> ${StyledText} {
      font-size: 24px;
      line-height: 26.4px;
    }
    .bottomText {
      margin: 30px 0 0 0;
    }
    .bottomText> ${StyledText} {
      font-size: 12px;
      line-height: 14.4px;
      width: 193px;
    }
    .bottomText> ${StyledText}> ${StyledText} {
      font-size: 12px;
      line-height: 14.4px;
    }
    .footer {
      padding: 380px 30px 0 30px;
    }
    .footer> ${StyledFlexContainer}> ${StyledText} {
      font-size: 24px;
      line-height: 28.8px;
    }
    
  }
`

export default OpenRestoranBlockMobileStyles
