import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import BackgroundImage from "../../../../img/Ellipse 100.png"
import Face from "../../../../img/Ellipse 95.png"
import WhiteLogo from "../../../../img/WhiteLogo.png"
import Button from "../../../../components/Button/page/Button";
import OpenRestoranBlockStyles from "../styles/OpenRestoranBlockStyles";

const OpenRestoranBlockPage = () => {

    return (
        <OpenRestoranBlockStyles>
        <BlockPage width="100%" height="1054px">
            <FlexContainerPage maxWidth="1018px" margin="283px 0 0 213px" flexDirection="column" zIndex={5} position="relative">
                <TextPage fontSize="100px" color="red" width="100%" fontWeight="800" lineHeight="118px">
                    ОТКРОЙ РЕСТОРАН ИЗ МИРА АЗИИ
                </TextPage>
                <FlexContainerPage>
                <TextPage fontSize="48px" color="black" width="100%" fontWeight="800">
                    и зарабатывай от 300 000 руб. в месяц
                </TextPage>

                </FlexContainerPage>
                <FlexContainerPage flexDirection="row">
                    <Button margin="169px 0 0 0" width="370px" height="56px" fontSize="24px" color="white"
                            fontWeight='600' cursor='pointer' borderRadius="12px" $backgroundColor="red">
                        получить звонок
                    </Button>
                    <FlexContainerPage>
                        <ImagePage src={Face} margin="137px 0 0 32px" width="120px"/>
                        <FlexContainerPage margin="164px 0 0 32px" flexDirection="column">
                            <TextPage fontSize="18px" fontWeight="700" color="black">
                                Юлия Бондаренко
                            </TextPage>
                            <TextPage width="354px" height="66px" fontSize="18px" fontWeight="600"
                                      color="black">
                                помогу открыть франшизу
                                <br/>
                                в твоём городе
                            </TextPage>
                        </FlexContainerPage>

                    </FlexContainerPage>
                </FlexContainerPage>
            </FlexContainerPage>
            <ImagePage src={BackgroundImage}  position="absolute" zIndex={0} right="0" top="0"/>
            <BlockPage  height="369px" $backgroundColor="#FF0032" margin="134px 0 0 0">
                <FlexContainerPage padding="56px 0 0 213px" flexDirection="column">
                    <ImagePage src={WhiteLogo}/>
                    <FlexContainerPage>
                        <TextPage lineHeight="57.6px" fontSize="48px" fontWeight="500"  height="174px" color="white" margin="23px 0 0 0">
                            – это сеть ресторанов японской и паназиатской кухни,<p> созданная людьми, которые  <span style={{fontWeight:"800"}}>любят </span>
                         вкус

                        для людей, которые его <span style={{fontWeight:"800"}}>ценят </span></p>
                        </TextPage>
                    </FlexContainerPage>

                </FlexContainerPage>
            </BlockPage>
        </BlockPage>
        </OpenRestoranBlockStyles>
    );
};

export default OpenRestoranBlockPage;