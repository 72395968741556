import React from "react";
import ReactDOM from "react-dom";
import App from './App';
import {createGlobalStyle, ThemeProvider} from "styled-components";
import GilroyRegular from './fonts/Gilroy-Regular.woff';
import GilroyBold from './fonts/Gilroy-Bold.woff';

const Global = createGlobalStyle`
    @font-face {
        font-family: 'Gilroy';
        src: local('Gilroy'),
        url(${GilroyRegular}) format('woff');
        font-weight: 400;
    }
    
    @font-face {
        font-family: 'Gilroy';
        src: local('Gilroy'),
        url(${GilroyBold}) format('woff');
        font-weight: 700;
    }
    
    *, *:after, *:before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Gilroy","PT Sans",sans-serif;
        user-select: none;
        -webkit-user-drag: none;
    }
    
    body::-webkit-scrollbar {
        display: none;
    }
    body {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
`

const theme = {
    colors: {
        text: {
            black: '#000',
            dark: '#333333',
            lightDark: '#666666',
            grey: '#999999',
            lightGrey: '#B1B3B3',
            darkGrey: '#4a4a4a',
            lightRed: '#eb1f40',
            red: '#FF0032',
            darkRed: "#ED0213",
            white: '#fff',
            lightBlue: "#61D6FF"

        },
        background: {
            blue: '#8ed8f7',
            red: '#FF0032',
            lightRed: '#eb1f40',
            green: '#9fe48b',
            white: '#fff',
            grey: '#efeff4'
        }
    },
}

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Global />
            <App/>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

