import React from 'react';
import SishiVeslaTodayMobileStyles from "../styles/SishiVeslaTodayMobileStyles";
import BlockPage from "../../../../components/Block/page/BlockPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import TextPage from "../../../../components/Text/page/TextPage";
import Button from "../../../../components/Button/page/Button";
import Grid from "../../../../components/container/Grid/page/Grid";

const SishiVeslaTodayMobilePage = () => {
    return (
        <SishiVeslaTodayMobileStyles>
            <BlockPage id="about">
                <FlexContainerPage flexDirection="column" $justifyContent="center" $alignItems="center" margin="48px 16px 80px 16px">
                    <TextPage fontWeight="800" fontSize="64px" color="red"
                              padding="0 0 32px 0" position="relative" zIndex="4">
                        СЕГОДНЯ МЫ - ЭТО:
                    </TextPage>
                    <FlexContainerPage  margin="0 16px 0 16px" flexDirection="column" width="100%">
                        <Grid padding="48px 20px 48px 20px" width="100%" gridTemplateColumns="repeat(1, 1fr)" gridRowGap="52px" backgroundColor="#FF0032" borderRadius="12px">
                            <FlexContainerPage flexDirection="column" $alignItems="center">
                                <TextPage color="white" fontWeight="800" fontSize="48px" lineHeight="56.64px">
                                    123
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="24px" lineHeight="43.2px" textAlign="center">
                                    ресторана <br/>
                                    в 24 регионах России
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage flexDirection="column" $alignItems="center">
                                <TextPage color="white" fontWeight="800" fontSize="48px" lineHeight="56.64px">
                                    8 млн
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="24px" lineHeight="43.2px" textAlign="center">
                                    гостей по сети<br/>
                                    ежегодно
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage flexDirection="column" $alignItems="center">
                                <TextPage color="white" fontWeight="800" fontSize="48px" lineHeight="56.64px">
                                    5 000
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="24px" lineHeight="43.2px" textAlign="center">
                                    заказов<br/>
                                    каждый день
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage flexDirection="column" $alignItems="center" flexGrow="1" margin="44px 0 0 0">
                                <TextPage color="white" fontWeight="800" fontSize="48px" lineHeight="56.64px">
                                    1,5 года
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="24px" lineHeight="43.2px" textAlign="center">
                                    средняя окупаемость ресторана
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage flexDirection="column" $alignItems="center" margin="44px 0 0 0">
                                <TextPage color="white" fontWeight="800" fontSize="48px" lineHeight="56.64px">
                                    6 млн
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="24px" lineHeight="43.2px" textAlign="center">
                                    наибольшая выручка<br/>
                                    за месяц
                                </TextPage>
                            </FlexContainerPage>
                            <FlexContainerPage flexDirection="column" $alignItems="center" margin="44px 0 0 0">
                                <TextPage color="white" fontWeight="800" fontSize="48px" lineHeight="56.64px">
                                    60%
                                </TextPage>
                                <TextPage color="white" fontWeight="600" fontSize="24px" lineHeight="43.2px" textAlign="center">
                                    гостей совершают повторный заказ
                                </TextPage>
                            </FlexContainerPage>
                        </Grid>
                        <FlexContainerPage position="relative" zIndex={4} $justifyContent="center" width="100%" margin="40px 0 0 0">
                            <Button width="100%" height="56px" $backgroundColor="white" color="red" fontSize="24px" fontWeight="600" borderRadius="12px" cursor="pointer" border="2px solid red">
                                стать партнером
                            </Button>
                        </FlexContainerPage>
                    </FlexContainerPage>

                </FlexContainerPage>
            </BlockPage>
        </SishiVeslaTodayMobileStyles>
    );
};

export default SishiVeslaTodayMobilePage;