import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import StrengthsBlockStyles from "../styles/StrengthsBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

import FirstImage from "../../../../img/Group 274 (1).png"
import SecondImage from "../../../../img/Group 274.png"
import ThirdImage from "../../../../img/Group 351.png"
import FourthImage from "../../../../img/Group 276.png"
import FifthImage from "../../../../img/Group 352.png"
import SixthImage from "../../../../img/Group 275.png"

import BackgroundImage from "../../../../img/Ellipse 74.png"


const StrengthsBlockPage = () => {

    return (
        <StrengthsBlockStyles>
        <BlockPage width="100%" $backgroundColor="#B2F0A0" height="1080px" id="strengths">
                <ImagePage src={BackgroundImage} position="absolute" zIndex={1} right="0" margin="210px 0 0 0"/>
            <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center" position="relative" zIndex={2}>
                <TextPage color="red" fontSize="100px" fontWeight="800" textAlign="center" lineHeight="118px" margin="62px 0 0 0">
                    СИЛЬНЫЕ СТОРОНЫ
                </TextPage>
                <Grid margin="96px 0 0 0" gridTemplateColumns="repeat(3, 1fr)" gridRowGap="98px" gridColumnGap="40px">
                    <FlexContainerPage flexDirection="column"  width="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={FirstImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            передовая FoodTech компания
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            бизнес построенный на IT и полная
                            автоматизация процессов
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column"  width="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={SecondImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            концепция
                            проверенная временем
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            успешная бизнес-стратегия провереннная годами

                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column"  width="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={ThirdImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            понятный продукт
                            по доступной цене
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            созданный согласно запросу нашего гостя
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column"  width="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={FourthImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            собственное мобильное приложение
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            и автоматизированная
                            система доставки
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column"  width="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={FifthImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            полная маркетинговая поддержка
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            от момента привлечения гостя
                            до его удержания
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column"  width="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={SixthImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            современная система обучения
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            в онлайн-формате
                            для партнеров и сотрудников
                        </TextPage>
                    </FlexContainerPage>

                </Grid>
            </FlexContainerPage>
        </BlockPage>
        </StrengthsBlockStyles>
    );
};

export default StrengthsBlockPage;