import React from 'react';
import GridProps from "../props/GridProps";
import GridStyles from "../styles/GridStyles";

const Grid = ({children, ...props}:GridProps) => {
    return (
        <GridStyles {...props}>
            {children}
        </GridStyles>
    );
};

export default Grid;