import styled from "styled-components";
import GridProps from "../props/GridProps";

const GridStyles = styled.div<GridProps>`  
    display: grid;
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : ''};    
    grid-template-columns: ${({gridTemplateColumns}) => gridTemplateColumns ? gridTemplateColumns : ''};
    grid-row-gap: ${({gridRowGap}) => gridRowGap ? gridRowGap : ''};
    grid-column-gap: ${({gridColumnGap}) => gridColumnGap ? gridColumnGap : ''};
    width: ${({width}) => width ? width : ''};
    background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : ''};
    height: ${({height}) => height ? height : ''};
    margin: ${({margin}) => margin ? margin : ''};
    padding: ${({padding}) => padding ? padding : ''};
    cursor: ${({cursor}) => cursor ? cursor : ''};  
    z-index: ${({zIndex}) => zIndex ? zIndex : ''};  
    position: ${({position}) => position ? position : ''};  
    max-width: ${({maxWidth}) => maxWidth ? maxWidth : ''};
    top:  ${({top}) => top ? top : ''};
    right:  ${({right}) => right ? right : ''};
    left:  ${({left}) => left ? left : ''};
    bottom:  ${({bottom}) => bottom ? bottom : ''};
    align-items: ${({alighItems}) => alighItems ? alighItems : ''};
`

export default GridStyles