import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";

export const StyledModalCarousel = styled(Carousel)`
    width: 700px;
    margin-left: 40px;
  @media screen and (max-width: 1200px){
    width: 500px;
  }
  
`