import { useEffect } from "react";

const useScript = () => {        
    useEffect(()=> {
        console.log(123144);        
        //@ts-ignore
        return(function(w,d,u){ var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0); var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h); })
        (window,document,'https://cdn-ru.bitrix24.ru/b3020343/crm/form/loader_45.js');
    })    
}
export default useScript