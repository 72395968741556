import styled from "styled-components";
import TextProps from "../props/TextProps";

export const StyledText = styled.span<TextProps>`
    width: ${({width}) => width ? width : ''};
    max-width: ${({maxWidth}) => maxWidth ? maxWidth : ''};
    height: ${({height}) => height ? height : ''};
    display: ${({display}) => display ? display : 'block'};
    font-size: ${({fontSize}) => fontSize ? fontSize : '13px'};
    line-height: ${({lineHeight}) => lineHeight ? lineHeight : ''};
    font-weight: ${({fontWeight}) => fontWeight ? fontWeight : '400'};
    color: ${({color, theme}) => color ? theme.colors.text[color] : theme.colors.text.grey};
    margin: ${({margin}) => margin ? margin : 0};
    padding: ${({padding}) => padding ? padding : 0};
    text-transform: ${({uppercase}) => uppercase ? 'uppercase' : ''};
    text-align: ${({textAlign}) => textAlign ? textAlign : ''};
    border-bottom: ${({borderBottom}) => borderBottom ? borderBottom : ''};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : ''};
    cursor: ${({cursor}) => cursor ? cursor : ''};
    transition: ${({transition}) => transition ? transition : ''};
    overflow: ${({overflow}) => overflow ? overflow : ''};
    text-overflow: ${({textOverflow}) => textOverflow ? textOverflow : ''};
    white-space: ${({whiteSpace}) => whiteSpace ? whiteSpace : ''};
    -webkit-line-clamp: ${({lineClamp}) => lineClamp ? lineClamp : ''};
    -webkit-box-orient: ${({boxOrient}) => boxOrient ? boxOrient : ''};
    right: ${({right}) => right ? right : ''};
    position: ${({position}) => position ? position : ''};
    z-index: ${({zIndex}) => zIndex ? zIndex : ''};
    background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : ''};
    left: ${({left}) => left ? left : ''};
    
    &:hover {
        color: ${({hoverColor, theme}) => hoverColor ? theme.colors.text[hoverColor] : ''};
    }
`