import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import BecomeAPartnerMobileBlockStyles from "../styles/BecomeAPartnerMobileBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import Input from "../../../../components/Input/page/InputPage";
import Button from "../../../../components/Button/page/Button";
import useScript from "../hooks/useScript";



const BecomeAPartnerMobileBlockPage = () => {

    const script = useScript()
    //@ts-ignore
    return <script data-b24-form="inline/45/u37j5r" data-skip-moving="true">{script}</script>
};

export default BecomeAPartnerMobileBlockPage;