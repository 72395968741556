import CarouselBlockProps from "../props/CarouselBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";
import {StyledCarousel} from "../components/StyledCarousel";

const CarouselBlockStyles = styled.div<CarouselBlockProps>`
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} {
      width: 100%;
      height: 768px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}:nth-child(1) {
      margin: 40px 0 0 60px;
    }
  }


  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledText} {
      font-size: 64px;
      line-height: 75.52px;
      width: 619px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledImage} {
      margin: -80px 0 0 319px;
      width: 193px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel} {
      width: 950px;
    }
  }

  @media screen and (max-width: 1700px) {
    .control-dots {
      margin: 0px 8px 115px 188px !important;
    }
  }

  @media screen and (max-width: 1700px) {
    .control-dots> li {
      background: white !important;
    }
  }

  @media screen and (max-width: 1700px) {
    .control-dots>.selected {
      background: red !important;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel}> div> div> ul> li> ${GridStyles}  {
      
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel}> div> div> ul> li> ${GridStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(1) {
      font-size: 48px;
      line-height: 56.64px;
      width: 291px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel}> div> div> ul> li> ${GridStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(2) {
      font-size: 20px;
      line-height: 24px;
      width: 291px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel}> div> div> ul> li> ${GridStyles}> ${StyledFlexContainer}> ${StyledFlexContainer} {
      flex-direction: column;
      row-gap: 20px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel}> div> div> ul> li> ${GridStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${ButtonStyles} {
      width: 321px;
      border-radius: 12px;
      font-size: 24px;
      line-height: 16px;
      padding: 15px 29px 15px 29px
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel}> div> div> ul> li> ${GridStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${ButtonStyles}> ${StyledImage} {
      margin: 0 14px 0 0;
    }
  }
  
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel}> div> div> ul> li> ${GridStyles}> ${StyledFlexContainer}:nth-child(2)> ${StyledImage}:nth-child(1) {
      width: 488px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel}> div> div> ul> li> ${GridStyles}> ${StyledFlexContainer}:nth-child(2)> ${StyledImage}:nth-child(2) {
      width: 680px;
      margin: -92px -101px 1px 1px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel}> div> div> ul> li> ${GridStyles}> ${StyledFlexContainer}:nth-child(2)> ${StyledImage}:nth-child(3) {
      width: 680px;
      margin: 0px -48px 0 0;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledCarousel}> div> div> ul> li> ${GridStyles}> ${StyledFlexContainer}:nth-child(2)> ${StyledImage}:nth-child(4) {
      width: 205px;
    }
  }


  @media screen and (max-width: 1700px) {
    .Mac {
      width: 570px !important;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}:nth-child(2) {
      width: 100%;
      height: 156px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}:nth-child(2)> ${StyledFlexContainer} {
      padding: 40px 0 0 0;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}:nth-child(2)> ${StyledFlexContainer}> ${StyledImage} {
       width: 291px;
      margin: 0;
     }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}:nth-child(2)> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledText} {
      font-size: 20px;
      line-height: 24px;
      width: 597px;
    }
  }

`

export default CarouselBlockStyles
