import StyledBurgerMenuMobile from "../styles/BurgerMenuMobileStyles";
import { slide as Menu } from 'react-burger-menu'
import ImagePage from "../../../../components/Image/page/ImagePage";

import OpenButton from '../media/Без имени-1.png'
import CloseButton from '../media/Group 364.png'
import Logo from '../media/Frame.svg'
import Button from "../../../../components/Button/page/Button";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useState } from "react";



const BurgerMenuMobilePage = () => {

  const [isOpen, setOpen] = useState(false)

  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const openSideBar = () => {
    setOpen(true)
  }

  const closeSideBar = () => {
    setOpen(false)
  }

  return (
    <StyledBurgerMenuMobile>
      <Menu customBurgerIcon={<ImagePage src={OpenButton} onClick={openSideBar}/>}
            customCrossIcon={<ImagePage src={CloseButton}/>}
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}>
        <ImagePage position="fixed" className="menu-logo" src={Logo}/>
        <AnchorLink href="#about" className="link">
          <span className="menu-item first-item" onClick={closeSideBar}>О НАС</span>
        </AnchorLink>
        <AnchorLink href="#strengths" className="link">
          <span className="menu-item" onClick={closeSideBar}>ПРЕИМУЩЕСТВА</span>
        </AnchorLink>
        <AnchorLink href="#formats" className="link">
          <span className="menu-item" onClick={closeSideBar}>ФОРМАТЫ</span>
        </AnchorLink>
        <AnchorLink href="#open" className="link">
          <span className="menu-item" onClick={closeSideBar}>ЭТАПЫ ОТКРЫТИЯ</span>
        </AnchorLink>
        <Button className="button">оставить заявку</Button>
      </Menu>
    </StyledBurgerMenuMobile>
    )
}

export default BurgerMenuMobilePage