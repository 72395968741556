import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import FooterBlockStyles from "../styles/FooterBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";
import Input from "../../../../components/Input/page/InputPage";
import Button from "../../../../components/Button/page/Button";

import Telegram from "../media/Соц-02.png"
import TicTok from "../media/Соц-05.png"
import Logo from "../media/Frame.png"

const FooterBlockPage = () => {

    return (
        <FooterBlockStyles>
            <BlockPage width="100%" height="100%">
                <FlexContainerPage padding="86px 0 0 0" $justifyContent="center" columnGap="76px">
                    <FlexContainerPage flexDirection="column" rowGap="16px">
                        <TextPage fontSize="12px" lineHeight="16px" fontWeight="700" color="red">
                            контакты
                        </TextPage>
                        <TextPage fontSize="12px" lineHeight="16px" fontWeight="400" color="black">
                            +7 (999) 999-99-99
                        </TextPage>
                        <TextPage fontSize="12px" lineHeight="16px" fontWeight="400" color="black">
                            +7 (999) 999-99-99
                        </TextPage>
                        <TextPage fontSize="12px" lineHeight="16px" fontWeight="400" color="black">
                            г. Волгоград ул Чуйкова д. 37
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column" $justifyContent="center" $alignItems="center">
                        <ImagePage src={Logo}/>
                        <FlexContainerPage columnGap="16px" margin="27px 0 0 0">
                            <ImagePage src={Telegram}/>
                            <ImagePage src={TicTok}/>
                        </FlexContainerPage>
                        <TextPage fontSize="12px" lineHeight="16px" fontWeight="400" color="black" margin="18px 0 0 0">
                            franchaizi@sushivesla.team
                        </TextPage>
                        <TextPage fontSize="12px" lineHeight="16px" fontWeight="400" color="grey" margin="18px 0 0 0">
                            © 2022 Сушивёсла
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column" rowGap="16px">
                        <TextPage fontSize="12px" lineHeight="16px" fontWeight="700" color="red">
                            соглашение
                        </TextPage>
                        <TextPage fontSize="12px" lineHeight="16px" fontWeight="400" color="black" width="122px">
                            политика
                            конфиденциальности
                        </TextPage>

                    </FlexContainerPage>
                </FlexContainerPage>
            </BlockPage>
        </FooterBlockStyles>
    );
};

export default FooterBlockPage;