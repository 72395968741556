import DesktopHeaderPage from "./desktop/Frames/Header/page/DesktopHeaderPage";
import {useTheme} from "styled-components";
import OpenRestoranBlockPage from "./desktop/Frames/OpenRestoranBlock/page/OpenRestoranBlockPage";
import OurProductBlockPage from "./desktop/Frames/OurProductBlock/page/OurProductBlockPage";
import SushiVeslaTodayPage from "./desktop/Frames/SishiVeslaToday/page/SushiVeslaTodayPage";
import StrengthsBlockPage from "./desktop/Frames/StrengthsBlock/page/StrengthsBlockPage";
import RestaurantFormatsBlockPage from "./desktop/Frames/RestaurantFormatsBlock/page/RestaurantFormatsBlockPage";
import BlubsWithTextBlockPage from "./desktop/Frames/BlubsWithTextBlock/page/BlubsWithTextBlockPage";
import CarouselBlockPage from "./desktop/Frames/CarouselBlock/page/CarouselBlockPage";
import HowDoWeWorkWithClientBlockPage
    from "./desktop/Frames/HowDoWeWorkWithClientBlock/page/HowDoWeWorkWithClientBlockPage";
import OpenTheWorldOfAsiaBlockPage
    from "./desktop/Frames/OpenTheWorldOfAsiaBlock/page/OpenTheWorldOfAsiaBlockPage";
import OurTeamBlockPage from "./desktop/Frames/OurTeamBlock/page/OurTeamBlockPage";
import LookAtUsBlockPage from "./desktop/Frames/LookAtUsBlock/page/LookAtUsBlockPage";
import BecomeAPartnerBlockPage from "./desktop/Frames/BecomeAPartnerBlock/page/BecomeAPartnerBlockPage";
import FooterBlockPage from "./desktop/Frames/FooterBlock/page/FooterBlockPage";
import {useAdaptive} from "./hooks/useAdaptive";
import OpenRestoranBlockMobilePage from "./mobile/Frames/OpenRestoranBlockMobile/page/OpenRestoranBlockMobilePage";
import OurProductMobileBlockPage from "./mobile/Frames/OurProductMobileBlock/page/OurProductMobileBlockPage";
import SishiVeslaTodayMobilePage from "./mobile/Frames/SishiVeslaTodayMobileBlock/page/SishiVeslaTodayMobilePage";
import StrengthsMobileBlockPage from "./mobile/Frames/StrengthsMobileBlock/page/StrengthsMobileBlockPage";
import RestaurantFormatsMobileBlockPage
  from "./mobile/Frames/RestaurantFormatsMobileBlock/page/RestaurantFormatsMobileBlockPage";
import BlubsWithTextMobileBlockPage from "./mobile/Frames/BlubsWithTextMobileBlock/page/BlubsWithTextMobileBlockPage";
import CarouselMobileBlockPage from "./mobile/Frames/CarouselMobileBlock/page/CarouselMobileBlockPage";
import HowDoWeWorkWithClientMobileBlockPage
  from "./mobile/Frames/HowDoWeWorkWithClientMobileBlock/page/HowDoWeWorkWithClientMobileBlockPage";
import OpenTheWorldOfAsiaMobileBlockPage
  from "./mobile/Frames/OpenTheWorldOfAsiaMobileBlock/page/OpenTheWorldOfAsiaMobileBlockPage";
import OurTeamMobileBlockPage from "./mobile/Frames/OurTeamMobileBlock/page/OurTeamMobileBlockPage";
import LookAtUsMobileBlockPage from "./mobile/Frames/LookAtUsMobileBlock/page/LookAtUsMobileBlockPage";
import BecomeAPartnerMobileBlockPage
  from "./mobile/Frames/BecomeAPartnerMobileBlock/page/BecomeAPartnerMobileBlockPage";
import FooterMobileBlockPage from "./mobile/Frames/FooterMobileBlock/page/FooterMobileBlockPage";
import DarkKitchenModalWindow from "./desktop/Frames/RestaurantFormatsBlock/components/DarkKitchenModalWindow";
import { useState } from "react";
import FoodCortModalWindow from "./desktop/Frames/RestaurantFormatsBlock/components/FoodCortModalWindow";
import StreetModalWindow from "./desktop/Frames/RestaurantFormatsBlock/components/StreetModalWindow";
import BoutiqueModalWindow from "./desktop/Frames/RestaurantFormatsBlock/components/BoutiqueModalWindow";
import DarkKitchenModalWindowMobile
  from "./mobile/Frames/RestaurantFormatsMobileBlock/components/DarkKitchenModalWindowMobile";
import FoodCortModalWindowMobile
  from "./mobile/Frames/RestaurantFormatsMobileBlock/components/FoodCortModalWindowMobile";
import StreetModalWindowMobile from "./mobile/Frames/RestaurantFormatsMobileBlock/components/StreetModalWindowMobile";
import BoutiqueModalWindowMobile
  from "./mobile/Frames/RestaurantFormatsMobileBlock/components/BoutiqueModalWindowMobile";
import BurgerMenuMobilePage from "./mobile/Frames/BurgerMenuMobile/page/BurgerMenuMobilePage";

const App = () => {
  const [showDarkKinchenModal, setShowDarkKinchenModal] = useState(false);
  const [showFoodCortModal, setShowFoodCortModal] = useState(false);
  const [showStreetModal, setShowStreetModal] = useState(false);
  const [showBoutiqueModal, setShowBoutiqueModal] = useState(false);

  const [showDarkKinchenModalMobile, setShowDarkKinchenModalMobile] = useState(false);
  const [showFoodCortModalMobile, setShowFoodCortModalMobile] = useState(false);
  const [showStreetModalMobile, setShowStreetModalMobile] = useState(false);
  const [showBoutiqueModalMobile, setShowBoutiqueModalMobile] = useState(false);

  const openDarkKinchenModal = () => {
    setShowDarkKinchenModal(prev => !prev);
  };

  const openFoodCortModal = () => {
    setShowFoodCortModal(prev => !prev);
  };

  const openStreetModal = () => {
    setShowStreetModal(prev => !prev);
  };

  const openBoutiqueModal = () => {
      setShowBoutiqueModal(prev => !prev);
    };


  const openDarkKinchenModalMobile = () => {
    setShowDarkKinchenModalMobile(prev => !prev);
  };
  const openFoodCortModalMobile = () => {
      setShowFoodCortModalMobile(prev => !prev);
    };
  const openStreetModalMobile = () => {
      setShowStreetModalMobile(prev => !prev);
    };
  const openBoutiqueModalMobile = () => {
      setShowBoutiqueModalMobile(prev => !prev);
    };



  const mobile = useAdaptive()

        if(mobile)
        {
          const el = document.getElementsByClassName("b24-form")[0]
          if (el) {
            el.remove()
          }
            return (
                <div style={{overflow: "hidden"}}>
                    <BurgerMenuMobilePage />
                    <DarkKitchenModalWindowMobile showModal={showDarkKinchenModalMobile} setShowModal={setShowDarkKinchenModalMobile}/>
                    <FoodCortModalWindowMobile showModal={showFoodCortModalMobile} setShowModal={setShowFoodCortModalMobile}/>
                    <StreetModalWindowMobile showModal={showStreetModalMobile} setShowModal={setShowStreetModalMobile}/>
                    <BoutiqueModalWindowMobile showModal={showBoutiqueModalMobile} setShowModal={setShowBoutiqueModalMobile}/>
                    <OpenRestoranBlockMobilePage />
                    <OurProductMobileBlockPage />
                    <SishiVeslaTodayMobilePage />
                    <StrengthsMobileBlockPage />
                    <RestaurantFormatsMobileBlockPage showDarkKitchen={openDarkKinchenModalMobile}
                                                      showFoodCort={openFoodCortModalMobile} showStreet={openStreetModalMobile} showBoutique={openBoutiqueModalMobile}/>
                    <BlubsWithTextMobileBlockPage />
                    <CarouselMobileBlockPage />
                    <HowDoWeWorkWithClientMobileBlockPage />
                    <OpenTheWorldOfAsiaMobileBlockPage />
                    <OurTeamMobileBlockPage />
                    <LookAtUsMobileBlockPage />
                    <BecomeAPartnerMobileBlockPage />
                    <FooterMobileBlockPage />
                </div>
            )
        }else{
            return (
                <div style={{overflow: "hidden"}}>
                    <DesktopHeaderPage />
                    <DarkKitchenModalWindow showModal={showDarkKinchenModal} setShowModal={setShowDarkKinchenModal}/>
                    <FoodCortModalWindow showModal={showFoodCortModal} setShowModal={setShowFoodCortModal}/>
                    <StreetModalWindow showModal={showStreetModal} setShowModal={setShowStreetModal}/>
                    <BoutiqueModalWindow showModal={showBoutiqueModal} setShowModal={setShowBoutiqueModal}/>
                    <OpenRestoranBlockPage/>
                    <OurProductBlockPage />
                    <SushiVeslaTodayPage/>
                    <StrengthsBlockPage />
                    <RestaurantFormatsBlockPage showDarkKitchen={openDarkKinchenModal} showFoodCort={openFoodCortModal} showStreet={openStreetModal} showBoutique={openBoutiqueModal}/>
                    <BlubsWithTextBlockPage />
                    <CarouselBlockPage />
                    <HowDoWeWorkWithClientBlockPage />
                    <OpenTheWorldOfAsiaBlockPage />
                    <OurTeamBlockPage />
                    <LookAtUsBlockPage />
                    <BecomeAPartnerBlockPage />
                    <FooterBlockPage />
                </div>
                )
        }

}

export default App;
