import OpenTheWorldOfAsiaBlockProps from "../props/OpenTheWorldOfAsiaBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";

const OpenTheWorldOfAsiaBlockStyles = styled.div<OpenTheWorldOfAsiaBlockProps>`

  .pulseAsia {
    animation: pulseAnimationAsia 1.5s infinite;
    border-radius: 50%;
  }

  @keyFrames pulseAnimationAsia {
    0% {
      transform: scale(0.1);
      opacity: 1;
    }

    70% {
      opacity: 0.5;
    }

    90% {
      
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
}
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles} {
      width: 100%;      
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledImage}:nth-child(1) {
      width: 555px;
      margin: 140px 0 0 0
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledImage}:nth-child(2) {      
      width: 758px;
      left: -280px;
      margin-top: -100px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledImage}:nth-child(3) {
      width: 1242px;
      margin: -50px 0 0 0;
      right: 260px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledImage}:nth-child(4) {
      width: 1634px;
      margin: -50px 0 0 0;
      right: 0;
    }
  }

  @media screen and (max-width: 1700px) {
    .openWorldOfAsia {
      margin: 192px 0 0 60px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledText} {
      font-size: 64px;
      line-height: 75.52px;
      width: 339.25px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledFlexContainer}> ${StyledButton} {
      margin: 24px 0 0 0;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities {
      margin: 134px 0 0 0;
      right: 154px;
    }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}> ${StyledText} {
      font-size: 20px;
      line-height: 22px;
      width: 131px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(1)> ${StyledImage}:nth-child(2) {
      width: 97px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(1)> ${StyledImage}:nth-child(3) {
      width: 56px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(2)> ${StyledImage}:nth-child(2) {
      width: 129px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(2)> ${StyledImage}:nth-child(3) {
      width: 74px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(3)> ${StyledImage}:nth-child(2) {
      width: 83px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(3)> ${StyledImage}:nth-child(3) {
      width: 47px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(4)> ${StyledImage}:nth-child(2) {
      width: 129px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(4)> ${StyledImage}:nth-child(3) {
      width: 74px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(5)> ${StyledImage}:nth-child(2) {
      width: 55px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(5)> ${StyledImage}:nth-child(3) {
      width: 32px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(6)> ${StyledImage}:nth-child(2) {
      width: 97px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(6)> ${StyledImage}:nth-child(3) {
      width: 55px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(7)> ${StyledImage}:nth-child(2) {
      width: 129px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(7)> ${StyledImage}:nth-child(3) {
      width: 74px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(7)> ${StyledImage}:nth-child(2) {
      width: 129px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(7)> ${StyledImage}:nth-child(3) {
      width: 74px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(8)> ${StyledImage}:nth-child(2) {
      width: 129px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(8)> ${StyledImage}:nth-child(3) {
      width: 74px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(1) {
      margin: 0;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(2) {
      margin: 120px 0 0 150px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(3) {
      margin: 40px 0 0 20px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(4) {
      margin: 10px 0 0 200px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(5) {
      margin: 50px 0 0 120px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(6) {
      margin: -10px 0 0 20px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(7) {
      margin: 100px 0 0 40px;
    }
  }

  @media screen and (max-width: 1700px) {
    .cities> ${StyledFlexContainer}:nth-child(8) {
      margin: -90px 0 0 180px;
    }
  }

  @media screen and (max-width: 1700px) {
    .inputBottom {
      margin: 138px 0 0 0;
    }
  }

  @media screen and (max-width: 1700px) {
    .inputBottom> ${StyledText} {
      width: 301px;
      font-size: 24px;
      line-height: 24px;
    }
  }
    
  @media screen and (max-width: 1700px) {
    .worldOfAsiaBottom {
      height: 156px;
      column-gap: 16px;
    }
  }

    @media screen and (max-width: 1700px) {
      .worldOfAsiaBottom> ${StyledImage}:nth-child(1) {
        width: 214px;
      }
    }
    
    @media screen and (max-width: 1700px) {
      .worldOfAsiaBottom> ${StyledImage}:nth-child(2) {
        width: 137px;
      }
    }

    @media screen and (max-width: 1700px) {
      .worldOfAsiaBottom> ${StyledFlexContainer}> ${StyledText}:nth-child(1) {
        font-size: 36px;
        width: 444px;
        line-height: 42.48px;
      }
    }

    @media screen and (max-width: 1700px) {
      .worldOfAsiaBottom> ${StyledFlexContainer}> ${StyledText}:nth-child(2) {
        font-size: 64px;
        width: 521px;
        line-height: 75.52px;
      }
    }
    
    
`

export default OpenTheWorldOfAsiaBlockStyles
