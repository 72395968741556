import styled from "styled-components";
import SishiVeslaTodayMobileProps from "../props/SishiVeslaTodayMobileProps";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";

const SishiVeslaTodayMobileStyles = styled.div<SishiVeslaTodayMobileProps>`

 

`

export default SishiVeslaTodayMobileStyles