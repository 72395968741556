import OurTeamMobileBlockProps from "../props/OurTeamMobileBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import ButtonStyles from "../../../../components/Button/styles/ButtonStyles";

const OurTeamMobileBlockStyles = styled.div<OurTeamMobileBlockProps>`
  position: relative;
  z-index: 10;
  
  @media screen and (min-width: 500px) {
    .cardsBlock {
      margin: 100px 0 0 0;
    }
  }
  
  @media screen and (max-width: 390px) {
    .bottomButton {
      width: 265px;
      margin-left: -90px;
      margin-top: -80px;
    }
  }


`

export default OurTeamMobileBlockStyles
