import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import Button from "../../../../components/Button/page/Button";
import OurProductMobileBlockStyles from "../styles/OurProductMobileBlockStyles";

import WhiteCircle1 from "../media/Ellipse 100.png";
import WhiteCircle2 from "../../../../img/Ellipse 111.png";
import TarTar1 from "../media/тартар-тунец-клубника-newсайт (1) копия 1.png";
import TarTar2 from "../../../../img/тартар-тунец-клубника-newсайт (1)-восстановлено копия 1.png";

const OurProductMobileBlockPage = () => {

  return (
    <OurProductMobileBlockStyles>
      <BlockPage $backgroundColor="#8ED8F7" height="100%" padding="0 0 100px 0">
        <FlexContainerPage flexDirection="column">
          <FlexContainerPage zIndex={2} position="relative" padding="80px 0 0 50px" className="topText">
            <TextPage fontSize="80px" fontWeight="800" color="red" width="245px" lineHeight="85.52px"
                      position="relative">
              НАШ ПРОДУКТ
            </TextPage>
          </FlexContainerPage>
          <FlexContainerPage $justifyContent="center" $alignItems="center" margin="300px 0 0 0" position="relative">
            <ImagePage src={WhiteCircle1} position="absolute" zIndex={1} className="smallBack" />
            <ImagePage src={WhiteCircle2} position="absolute" zIndex={1} className="bigBack" />
            <ImagePage src={TarTar1} position="absolute" zIndex={1} className="smallTartar" />
            <ImagePage src={TarTar2} position="absolute" zIndex={1} className="bigTartar" />
          </FlexContainerPage>
          <FlexContainerPage zIndex={2} position="relative" margin="300px 0 0 0" flexDirection="column" $alignItems="center" className="bottomText">
            <TextPage fontSize="36px" fontWeight="400" color="black" padding="0 50px 0 50px" lineHeight="43.2px"
                     >
              Уже 12 лет мы готовим вкуснейшие суши, роллы и воки в лучших традициях Азии.
              <br/>
              <br/>
              Наш приоритет - качественный продукт, передовые технологии и нестандартный подход к своему делу
            </TextPage>
            <Button $backgroundColor="white" borderRadius="12px" width="370px"
                    height="56px" color="red" fontSize="24px" fontWeight="600"
                    border="2px solid red" margin="32px 0 0 0">
              стать партнером
            </Button>
          </FlexContainerPage>
        </FlexContainerPage>


      </BlockPage>
    </OurProductMobileBlockStyles>
  );
};

export default OurProductMobileBlockPage;