import RestaurantFormatsBlockProps from "../props/RestaurantFormatsBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

const RestaurantFormatsBlockStyles = styled.div<RestaurantFormatsBlockProps>`
  
  .pulse {
    animation: pulseAnimation 1.3s infinite;
    background: rgba(97, 214, 255, 0.3);
    border-radius: 50%;
  }
  
  @keyFrames pulseAnimation {
    0% {
      box-shadow: 0 0 0 0 rgba(97, 214, 255, 0.3);;
    }
    
    70% {
    }
    
    90% {
      
    }    
    100% {
      box-shadow: 0 0 0 120px rgba(97, 214, 255, 0);
    }
  }
  
  @media screen and (max-width: 1700px) {
    .pulse {
      width: 300px !important;
    }
    & > ${BlockStyles} {
      width: 100%;
      height: 768px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${GridStyles} {
      margin: 214px 0 0 60px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${GridStyles}> ${StyledFlexContainer} {
      width: 444px;
      height: 340px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${GridStyles}> ${StyledFlexContainer} {
      width: 444px;
      height: 340px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${GridStyles}> ${StyledFlexContainer}> ${StyledImage} {
      width: 213px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${GridStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(2) {
      font-size: 64px;
      line-height: 75.52px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${GridStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(3) {
      font-size: 24px;
      line-height: 28.8px;
      width: 366px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${GridStyles}> ${GridStyles} {
      width: 542px;
      height: 541px;
      grid-row-gap: 37px;
      margin: -145px 0 0 -90px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${GridStyles}> ${GridStyles}> ${StyledFlexContainer}> ${StyledImage} {
      width: 236px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${GridStyles}> ${GridStyles}> ${StyledFlexContainer}> ${StyledText} {
      font-size: 24px;
    }
  }


`

export default RestaurantFormatsBlockStyles
