import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import StrengthsMobileBlockStyles from "../styles/StrengthsMobileBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

import FirstImage from "../../../../img/Group 274 (1).png"
import SecondImage from "../../../../img/Group 274.png"
import ThirdImage from "../../../../img/Group 351.png"
import FourthImage from "../../../../img/Group 276.png"
import FifthImage from "../../../../img/Group 352.png"
import SixthImage from "../../../../img/Group 275.png"

import BackgroundImage1 from "../../../../img/Ellipse 74.png"
import BackgroundImage2 from "../media/Ellipse 714.png"

import SmallTopBack from "../media/Ellipse 74.png"
import SmallBottomBack from "../media/Ellipse 102.png"
import Input from "../../../../components/Input/page/InputPage";
import Button from "../../../../components/Button/page/Button";


const StrengthsMobileBlockPage = () => {

    return (
        <StrengthsMobileBlockStyles>
        <BlockPage width="100%" $backgroundColor="#B2F0A0" id="strengths">
            <FlexContainerPage position="relative">
                <ImagePage src={BackgroundImage2} position="absolute" zIndex={1} left="0" margin="40px 0 0 0" className="bigTopBack" width="1200px"/>
                <ImagePage src={SmallTopBack} position="absolute" zIndex={1} left="0"  className="smallTopBack"/>
            </FlexContainerPage>
            <FlexContainerPage flexDirection="column" $alignItems="center" $justifyContent="center" position="relative" zIndex={2} margin="0 0 100px 0">
                <TextPage color="white" fontSize="64px" fontWeight="800" textAlign="center" lineHeight="75.52px" margin="62px 0 0 0">
                    СИЛЬНЫЕ СТОРОНЫ
                </TextPage>
                <Grid padding="96px 16px 0 16px" gridTemplateColumns="repeat(1, 1fr)" gridRowGap="98px" gridColumnGap="40px" alighItems="center">
                    <FlexContainerPage flexDirection="column" width="100%" maxWidth="472px"  minHeight="292px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={FirstImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            передовая FoodTech компания
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            бизнес построенный на IT и полная
                            автоматизация процессов
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column"   width="100%" maxWidth="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={SecondImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            концепция
                            проверенная временем
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            успешная бизнес-стратегия провереннная годами

                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column"   width="100%" maxWidth="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={ThirdImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            понятный продукт
                            по доступной цене
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            созданный согласно запросу нашего гостя
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column"   width="100%" maxWidth="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={FourthImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            собственное мобильное приложение
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            и автоматизированная
                            система доставки
                        </TextPage>
                    </FlexContainerPage>

                    <FlexContainerPage flexDirection="column"   width="100%" maxWidth="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={FifthImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            полная маркетинговая поддержка
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            от момента привлечения гостя
                            до его удержания
                        </TextPage>
                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column"   width="100%" maxWidth="472px" height="322px" backgroundColor="white" borderRadius="24px" $justifyContent="center" $alignItems="center">
                        <ImagePage src={SixthImage} width="146px" margin="-106px 0 0 0"/>
                        <TextPage color="red" fontSize="36px" lineHeight="43.2px" textAlign="center" fontWeight="800" margin="24px 0 0 0">
                            современная система обучения
                        </TextPage>
                        <TextPage color="black" fontSize="24px" fontWeight="600" lineHeight="28.8px" textAlign="center" margin="8px 0 0 0">
                            в онлайн-формате
                            для партнеров и сотрудников
                        </TextPage>
                    </FlexContainerPage>

                </Grid>

            </FlexContainerPage>
            <FlexContainerPage position="relative">
                <ImagePage src={BackgroundImage1} position="absolute" zIndex={1} margin="-900px 0 0 0" className="bigBottomBack" right="0"/>
                <ImagePage src={SmallBottomBack} position="absolute" zIndex={1} margin="-1500px 0 0 0" className="smallBottomBack" right="0"/>
            </FlexContainerPage>
            <FlexContainerPage position="relative" zIndex={10} flexDirection="column" margin="32px 0 0 16px" $alignItems="flex-start" rowGap="16px" $justifyContent="center" columnGap="31px" className="inputBottom">
                <TextPage fontSize="24px" fontWeight="600" lineHeight="28.8px" color="white">
                    узнай, как открыть
                    франшизу в своём городе
                </TextPage>
                <Input placeholder="email" width="342px" height="56px" border="2px solid red"
                       borderRadius="12px" padding="0 0 0 24px" fontSize="18px" fontWeight="600" color="rgba(0, 0, 0, 0.3)"/>
                <Button color="red" $backgroundColor="white" width="204px" height="56px" borderRadius="12px" fontSize="24px" fontWeight="600" border="2px solid red">
                    отправить
                </Button>
            </FlexContainerPage>
        </BlockPage>
        </StrengthsMobileBlockStyles>
    );
};

export default StrengthsMobileBlockPage;