import RestaurantFormatsMobileBlockProps from "../props/RestaurantFormatsMobileBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import ImagePage from "../../../../components/Image/page/ImagePage";
import GridStyles from "../../../../components/container/Grid/styles/GridStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

const RestaurantFormatsMobileBlockStyles = styled.div<RestaurantFormatsMobileBlockProps>`
  
  .wrapText {
    display: none;
  }
  
  @media screen and (max-width: 460px) {
    .wrapText {
      display: block;
    }
    .noWrapText {
      display: none;
    }
  }

  @media screen and (min-width: 560px) {
    &> ${BlockStyles}> ${StyledFlexContainer}> ${GridStyles}> ${StyledFlexContainer}> ${StyledImage} {
      width: 250px;
    }
  }


`

export default RestaurantFormatsMobileBlockStyles
