import First from "../media/1.png"
import Second from "../media/2.png"
import Third from "../media/3.png"
import Fourth from "../media/4.png"
import Fifth from "../media/5.png"
import Sixth from "../media/6.png"

export const TeamCards = [
    {
        img: First,
        title: "РОСТИСЛАВ МАСЛОВ",
        description: "директор it"
    },
    {
        img: Second,
        title: "ЯРОСЛАВ ЗИМИН",
        description: "эксперт по вкусу"
    },
    {
        img: Third,
        title: "ЛЮБОВЬ ФОРМИНИЧЕВА",
        description: "бренд менеджер"
    },
    {
        img: Fourth,
        title: "АНАСТАСИЯ ДЕМЕНКО",
        description: "арт. директор"
    },
    {
        img: Fifth,
        title: "СВЕТЛАНА КОПЫТОВСКАЯ",
        description: "директор hr"
    },
    {
        img: Sixth,
        title: "ЕЛЕНА БЕКТИНОВА",
        description: "креативный продюссер"
    },
]