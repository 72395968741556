import OurProductBlockProps from "../props/OurProductBlockProps";
import styled from "styled-components";
import {BlockStyles} from "../../../../components/Block/styles/BlockStyles";
import {StyledImage} from "../../../../components/Image/styles/ImageStyles";
import {StyledFlexContainer} from "../../../../components/container/Flex/styles/FlexContainerStyles";
import {StyledText} from "../../../../components/Text/styles/TextStyles";
import StyledButton from "../../../../components/Button/styles/ButtonStyles";

const OurProductBlockStyles = styled.div<OurProductBlockProps>`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}{
      height: 768px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledImage}:nth-child(2) {
      width: 557px;
      margin: 175px 0 0 9px;
    }
  }
  
  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledImage}:nth-child(1) {
      width: 557px;
      margin: 94px 0 0 137px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer} {
      top: 151px;
    }> ${StyledText}:nth-child(1) {
      font-size: 64px;
         }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(1) {
      font-size: 64px;
      line-height: 75.52px;
      right: 213px;
      width: 291px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledText}:nth-child(2) {
      font-size: 24px;
      line-height: 28.8px;
      right: 60px;
      width: 444px;
    }
  }

  @media screen and (max-width: 1700px) {
    & > ${BlockStyles}> ${StyledFlexContainer}> ${StyledButton} {      
      right: 155px;
      width: 349px;
    }
  }
`

export default OurProductBlockStyles
