import FirstImage from "../media/1.png"
import SecondImage from "../media/2.png"
import ThirdImage from "../media/3.png"
import FourthImage from "../media/4.png"
import FifthImage from "../media/5.png"
import SixthImage from "../media/6.png"
import SeventhImage from "../media/7.png"
import EightthImage from "../media/8.png"
import NinethImage from "../media/9.png"

export const CardsData = [
    {
        image: FirstImage,
        title: "знакомство",
        description: "познакомимся и расскажем обо всех этапах открытия"
    },
    {
        image: SecondImage,
        title: "договор",
        description: "Рассмотрим все юридические аспекты и подпишем договор"
    },
    {
        image: ThirdImage,
        title: "помещение",
        description: "поможем с поиском помещения и арендодателями"
    },
    {
        image: FourthImage,
        title: "проектирование",
        description: "создадим технологический эскиз и дизайн-проект"
    },
    {
        image: FifthImage,
        title: "строительство",
        description: "поможем на всех этапах \n" +
            "строительства и предоставим \n" +
            "свою базу подрядчиков "
    },
    {
        image: SixthImage,
        title: "обучение",
        description: "обучим и аттестуем партнеров \n" +
            "и сотрудников сети"
    },
    {
        image: SeventhImage,
        title: "персонал",
        description: "обучим и аттестуем партнеров \n" +
            "и сотрудников сети"
    },
    {
        image: EightthImage,
        title: "запуск",
        description: "поможем организовать крутое праздничное открытие"
    },
    {
        image: NinethImage,
        title: "сопровождение",
        description: "поддержим 24/7: снабжение, консультации, поиск сотрудников, маркетинг "
    }
]