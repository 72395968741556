import {StyledFlexContainer} from "../styles/FlexContainerStyles";
import FlexContainerProps from "../props/FlexContainerProps";

const FlexContainer = ({children, ...props}:FlexContainerProps) => {
    return (
        <StyledFlexContainer {...props}>
            {children}
        </StyledFlexContainer>
    )
}

export default FlexContainer