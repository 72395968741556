import BlockPage from "../../../../components/Block/page/BlockPage";
import TextPage from "../../../../components/Text/page/TextPage";
import FlexContainerPage from "../../../../components/container/Flex/page/FlexContainerPage";
import ImagePage from "../../../../components/Image/page/ImagePage";
import OurTeamMobileBlockStyles from "../styles/OurTeamMobileBlockStyles";
import Grid from "../../../../components/container/Grid/page/Grid";

import Back from "../media/Ellipse 74.png"
import ButtonBack1 from "../media/Ellipse 1.png"
import ButtonBack2 from "../media/Ellipse 2.png"
import Button from "../../../../components/Button/page/Button";

import First from "../media/1.png"
import Second from "../media/2.png"
import Third from "../media/3.png"
import Fourth from "../media/4.png"
import Fifth from "../media/5.png"
import Sixth from "../media/6.png"

import Logo from "../media/Frame.png"


// TODO: Добавить кружочки на фон и поменять полукруг на фоне
const OurTeamMobileBlockPage = () => {

    return (
        <OurTeamMobileBlockStyles>
            <BlockPage width="100%" $backgroundColor="#8ED8F7" height="100%">
                <ImagePage src={Back} margin="16px 0 0 78px" position="absolute" zIndex={6}/>
                <FlexContainerPage flexDirection="column" position="relative" zIndex={7} $justifyContent="center" $alignItems="center">
                    <FlexContainerPage flexDirection="column" margin="46px 0 0 16px">
                    <ImagePage src={Logo} width="171px" />
                    <TextPage color="white" textAlign="left" fontSize="60px" lineHeight="70.8px" fontWeight="800" >
                        НАША КОМАНДА
                    </TextPage>

                    </FlexContainerPage>
                    <FlexContainerPage flexDirection="column" rowGap="49px" margin="100px 0 0 -20%" position="relative" className="cardsBlock">
                                <FlexContainerPage columnGap="16px" >
                                    <ImagePage src={First} width="78px" />
                                    <FlexContainerPage flexDirection="Column" >
                                        <TextPage fontSize="24px" fontWeight="800" lineHeight="28.32px" color="white" maxWidth="200px">
                                            РОСТИСЛАВ МАСЛОВ
                                        </TextPage>
                                        <TextPage fontSize="18px" fontWeight="400" lineHeight="21.24px" color="white">
                                            директор it
                                        </TextPage>
                                    </FlexContainerPage>
                                </FlexContainerPage>
                        <FlexContainerPage columnGap="16px" margin="0 0 0 25%">
                            <ImagePage src={Second} width="78px" />
                            <FlexContainerPage flexDirection="Column" >
                                <TextPage fontSize="24px" fontWeight="800" lineHeight="28.32px" color="white" maxWidth="200px">
                                    ЯРОСЛАВ ЗИМИН
                                </TextPage>
                                <TextPage fontSize="18px" fontWeight="400" lineHeight="21.24px" color="white" whiteSpace="nowrap">
                                    эксперт по вкусу
                                </TextPage>
                            </FlexContainerPage>
                        </FlexContainerPage>
                        <FlexContainerPage columnGap="16px" margin="0 0 0 0">
                            <FlexContainerPage flexDirection="Column" $alignItems="flex-end">
                                <TextPage fontSize="24px" fontWeight="800" lineHeight="28.32px" color="white" maxWidth="200px" textAlign="right">
                                    Наталья
                                    Лепнухова
                                </TextPage>
                                <TextPage fontSize="18px" fontWeight="400" lineHeight="21.24px" color="white" textAlign="right" whiteSpace="nowrap">
                                    Дир. по Маркетингу
                                </TextPage>
                            </FlexContainerPage>
                            <ImagePage src={Third} width="78px" />
                        </FlexContainerPage>
                        <FlexContainerPage columnGap="16px" margin="0 0 0 47%">
                            <FlexContainerPage flexDirection="Column" $alignItems="flex-end">
                                <TextPage fontSize="24px" fontWeight="800" lineHeight="28.32px" color="white" maxWidth="200px" textAlign="right">
                                    АНАСТАСИЯ ДЕМЕНКО
                                </TextPage>
                                <TextPage fontSize="18px" fontWeight="400" lineHeight="21.24px" color="white" textAlign="right" whiteSpace="nowrap">
                                    арт. директор
                                </TextPage>
                            </FlexContainerPage>
                            <ImagePage src={Fourth} width="78px" />
                        </FlexContainerPage>
                        <FlexContainerPage columnGap="16px" margin="0 0 0 0">
                            <FlexContainerPage flexDirection="Column" $alignItems="flex-end">
                                <TextPage fontSize="24px" fontWeight="800" lineHeight="28.32px" color="white" maxWidth="200px" textAlign="right">
                                    ЛЮБОВЬ ФОМИНИЧЕВА
                                </TextPage>
                                <TextPage fontSize="18px" fontWeight="400" lineHeight="21.24px" color="white" textAlign="right" whiteSpace="nowrap">
                                    бренд менеджер
                                </TextPage>
                            </FlexContainerPage>
                            <ImagePage src={Third} width="78px" />
                        </FlexContainerPage>
                        <FlexContainerPage columnGap="16px" margin="0 0 0 25%">
                            <ImagePage src={Second} width="78px" />
                            <FlexContainerPage flexDirection="Column" >
                                <TextPage fontSize="24px" fontWeight="800" lineHeight="28.32px" color="white" maxWidth="200px">
                                    ЯРОСЛАВ ЗИМИН
                                </TextPage>
                                <TextPage fontSize="18px" fontWeight="400" lineHeight="21.24px" color="white" whiteSpace="nowrap">
                                    эксперт по вкусу
                                </TextPage>
                            </FlexContainerPage>
                        </FlexContainerPage>
                        <FlexContainerPage columnGap="16px" >
                            <ImagePage src={Fifth} width="78px" />
                            <FlexContainerPage flexDirection="Column" >
                                <TextPage fontSize="24px" fontWeight="800" lineHeight="28.32px" color="white" maxWidth="200px">
                                    СВЕТЛАНА КОПЫТОВСКАЯ
                                </TextPage>
                                <TextPage fontSize="18px" fontWeight="400" lineHeight="21.24px" color="white">
                                    директор hr
                                </TextPage>
                            </FlexContainerPage>
                        </FlexContainerPage>


                    </FlexContainerPage>
                    <FlexContainerPage $alignItems="center" $justifyContent="center" height="350px" className="bottomButton">
                        <Button color="red" $backgroundColor="white" width="370px" borderRadius="12px"
                                fontSize="24px" fontWeight="600" position="relative" border="2px solid red">
                            стать партнером
                        </Button>
                    </FlexContainerPage>

                </FlexContainerPage>
            </BlockPage>
        </OurTeamMobileBlockStyles>
    );
};

export default OurTeamMobileBlockPage;